<div class="privacy-container" fxLayout="row" fxLayoutAlign="center center">
  <ppf-modal class="om-modal privacy-modal">
    <button class="modal-close" (click)="closePrivacy()">
      <ppf-icon name="close"></ppf-icon>
    </button>
    <ppf-card>
      <div id="dr-privacynotice-div"></div>
    </ppf-card>
  </ppf-modal>
</div>
