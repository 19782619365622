import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigOffer, ConfiguredOffer, ConfiguredOffersInfo, PricesList, ConfigOption } from '@app/stepper/interfaces';
import { FormControl, FormGroup } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { CONFIG_OPTIONS, CONFIGURED_OFFER } from '@app/shared/constants/stepper.constants';
import * as copywrite from '../../../assets/global.json';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { Utils } from '@app/shared/services/utils';
import _ from 'lodash';

@Component({
  selector: 'om-configure-offer',
  templateUrl: './configure-offer.component.html',
  styleUrls: ['./configure-offer.component.scss'],
})
export class ConfigureOfferComponent implements OnInit {
  @Input() data$: Observable<ConfigOffer>;
  public offerData$: Observable<ConfiguredOffersInfo>;
  public gasConfigForm = new FormGroup({});
  public powerConfigForm = new FormGroup({});
  public globalConfigForm = new FormGroup({});
  public gasConfiguredOffer: ConfiguredOffer;
  public gasPrices: Array<PricesList>;
  public powerConfiguredOffer: ConfiguredOffer;
  public powerPrices: Array<PricesList>;
  public gasPricingMeta: PricesList | undefined;
  public powerPricingMeta: PricesList | undefined;
  public globalConfiguredOffer: ConfiguredOffer;
  public gasIncludedServices: Array<ConfigOption> = [];
  public powerIncludedServices: Array<ConfigOption> = [];
  public texts = copywrite.configureOffer;
  public continueAction: (values: any) => any;
  public backAction: () => any;
  CONFIG_OPTIONS = CONFIG_OPTIONS;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    Utils.scrollToTop();
    document.body.classList.add('enable-steps');
    this.offerData$ = this.data$.pipe(
      map((data) => {
        if (data && data.actionsFn && data.actions && data.actions.length) {
          this.backAction = data.actionsFn.back_in_steps;
          this.continueAction = data.actionsFn.save_offer;
        }
        return data.configuredOffersInformation;
      }),
      tap((data) => {
        this.gasConfiguredOffer = data?.configuredOffer.gas;
        this.gasPrices = data?.prices?.gas;
        this.powerConfiguredOffer = data?.configuredOffer.power;
        this.powerPrices = data?.prices?.power;
        this.globalConfiguredOffer = data?.globalConfiguredOffer;

        if (data.options && data.options.gas) {
          this.createOptionsForm(data.options.gas, this.gasConfigForm);
        }

        if (data.options && data.options.power) {
          this.createOptionsForm(data.options.power, this.powerConfigForm);
        }

        if (data.globalOptions) {
          this.createOptionsForm(data.globalOptions, this.globalConfigForm);
        }

        if (Object.keys(this.gasConfigForm.value).length) {
          this.getEstimatedCost(this.gasConfigForm, this.gasConfiguredOffer, this.gasPrices, true);
        }

        if (Object.keys(this.powerConfigForm.value).length) {
          this.getEstimatedCost(this.powerConfigForm, this.powerConfiguredOffer, this.powerPrices, false);
        }
      }),
    );
  }

  createOptionsForm(options, form: FormGroup): void {
    options.forEach((option) => {
      if (option.type === CONFIG_OPTIONS.TYPE_DROPDOWN) {
        return form.addControl(option.key, new FormControl(option.values[0]));
      }
      form.addControl(option.key, new FormControl(option.value));
    });
  }

  onSwitchChange(event, option, form, configuredOffer, prices, isGas, sendData = true, isGlobal = false): void {
    if (option.guid) {
      configuredOffer.fields.map((item) => {
        if (item.key === CONFIGURED_OFFER.INCLUDED_SERVICES_KEY) {
          if (event) {
            if (!isGlobal) {
              item.values.push(option.title);
            }
            isGas ? this.gasIncludedServices.push(option) : this.powerIncludedServices.push(option);
          } else {
            if (!isGlobal) {
              item.values = item.values.filter((value) => value !== option.title);
            }
            if (isGas) {
              this.gasIncludedServices = this.gasIncludedServices.filter((value) => value.guid !== option.guid);
            } else {
              this.powerIncludedServices = this.powerIncludedServices.filter((value) => value.guid !== option.guid);
            }
          }
        }
      });
    }

    if (isGlobal) {
      this.globalConfiguredOffer.fields.map((item) => {
        if (item.key === CONFIGURED_OFFER.INCLUDED_SERVICES_KEY) {
          item.values = _.intersection(this.gasIncludedServices, this.powerIncludedServices).map(
            (service) => service.title,
          );
        }
      });
    }

    this.getEstimatedCost(form, configuredOffer, prices, isGas);
    if (sendData) {
      this.sendFormChangesData(event, option);
    }
  }

  onSelectChange(event, option, form, configuredOffer, prices, isGas): void {
    switch (option.key) {
      case CONFIG_OPTIONS.CONTRACT_LENGTH_KEY: {
        configuredOffer.fields.map((item) => {
          if (item.key === CONFIGURED_OFFER.PRICE_AVAILABILITY_KEY) {
            item.values = [event.label];
          }
        });
        break;
      }

      case CONFIG_OPTIONS.PAYMENT_SCHEDULE_KEY: {
        configuredOffer.fields.map((item) => {
          if (item.key === CONFIGURED_OFFER.PAYMENT_SCHEDULE_KEY) {
            item.values = [event.label];
          }
        });
        break;
      }
    }
    this.sendFormChangesData(event, option);
    this.getEstimatedCost(form, configuredOffer, prices, isGas);
  }

  getEstimatedCost(form, configuredOffer, prices, isGas): any {
    const contractLength = form.value[CONFIG_OPTIONS.CONTRACT_LENGTH_KEY].value;
    const paymentSchedule = form.value[CONFIG_OPTIONS.PAYMENT_SCHEDULE_KEY].value;

    const pricingMeta = prices.find((p) => {
      return isGas
        ? p.contractMonthLength === contractLength &&
            p.paymentDays === paymentSchedule &&
            _.difference(
              this.gasIncludedServices.map((service) => service.guid),
              p.listOfServices.map((service) => service.serviceGuid),
            ).length === 0 &&
            this.gasIncludedServices.length === p.listOfServices.length
        : p.contractMonthLength === contractLength &&
            p.paymentDays === paymentSchedule &&
            _.difference(
              this.powerIncludedServices.map((service) => service.guid),
              p.listOfServices.map((service) => service.serviceGuid),
            ).length === 0 &&
            this.powerIncludedServices.length === p.listOfServices.length;
    });

    isGas ? (this.gasPricingMeta = pricingMeta) : (this.powerPricingMeta = pricingMeta);

    configuredOffer.fields.map((field) => {
      if (field.key === CONFIGURED_OFFER.FIXED_COST_KEY) {
        field.values = [pricingMeta?.gasPricePGT + ' ' + pricingMeta?.salesPriceUnit];
      }
    });
  }

  doAction(): void {
    const offers: any = {};

    if (this.gasConfiguredOffer) {
      if (!this.gasPricingMeta) {
        return;
      }

      offers.gasCrmSelectedOffer = this.gasPricingMeta;
    }

    if (this.powerConfiguredOffer) {
      if (!this.powerPricingMeta) {
        return;
      }

      offers.powerCrmSelectedOffer = this.powerPricingMeta;
    }

    if (this.continueAction) {
      document.body.classList.remove('enable-steps');
      this.continueAction({
        offers,
      }).subscribe();
    }

    this.googleAnalyticsService.eventEmitter(
      'Configurator continue',
      'custom_events',
      'configurator',
      'configurator_continue',
      1,
    );
  }

  sendFormChangesData(event, option): void {
    this.googleAnalyticsService.eventEmitter(
      'Configurator form events',
      'custom_events',
      'configurator',
      `${option.key}_${option.type}_${event.value || event}`,
      1,
    );
  }
}
