import { Component } from '@angular/core';
import * as copywrite from '@assets/global.json';
import { ModalService } from 'paperflow-web-components';

@Component({
  selector: 'om-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent {
  public texts = copywrite.infoModal;

  constructor(public modalService: ModalService) {}

  hideModal(): void {
    this.modalService.hide();
  }
}
