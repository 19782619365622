import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '@app/footer/footer.component';
import { AuthService } from '@app/core/auth/auth.service';
import { PrivacyModalComponent } from '@app/privacy-modal/privacy-modal.component';
import { OAuthModule, AuthConfig } from 'angular-oauth2-oidc';
import { OAuthModuleConfig, authConfig } from '@app/core/auth/auth.config';

export function init_app(authConfigService: AuthService): () => Promise<any> {
  return () => authConfigService.initAuth();
}

@NgModule({
  declarations: [HeaderComponent, FooterComponent, PrivacyModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: false,
      },
    }),
  ],
  providers: [
    AuthService,
    { provide: AuthConfig, useValue: authConfig },
    OAuthModuleConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AuthService],
      multi: true,
    },
  ],
  exports: [HeaderComponent, FooterComponent],
})
export class CoreModule {}
