<div *ngIf="contractualConditionsData$ | async as data; else loading" class="contractual-conditions-page">
  <div *ngIf="data.contractualConditionsInformation">
    <div class="headline page-title">{{ data.contractualConditionsInformation.title | flxLocalize }}</div>
    <div class="subtitle">{{ data.contractualConditionsInformation.subtitle | flxLocalize }}</div>

    <ppf-card class="page-card">
      <div class="card-content" [formGroup]="contractTermsForm" (ngSubmit)="saveForm()">
        <div fxLayout="row" class="title-wrapper">
          <div class="card-image mr-32">
            <img [src]="data.contractualConditionsInformation.image" class="image" alt="general terms and conditions" />
          </div>
          <div>
            <div class="title pb-8 pt-8 headline">{{ data.contractualConditionsInformation.cardTitle | flxLocalize }}</div>
            <div class="subtitle">{{ data.contractualConditionsInformation.cardSubtitle | flxLocalize }}</div>
          </div>
        </div>

        <div
          *ngIf="data.contractualConditionsInformation.summaryList?.gas?.length"
          fxLayout="row wrap"
          fxLayout.lt-md="column"
          class="summary-wrapper"
        >
          <div class="section-title green">
            {{ data.contractualConditionsInformation.summaryListTitle.gas }}
          </div>
          <div
            *ngFor="let summary of data.contractualConditionsInformation.summaryList.gas"
            class="summary-item"
            fxFlex="50"
            fxFlex.lt-md="100"
            fxLayout="row"
          >
            <div fxLayout="column"><img [src]="summary.image" class="small-image" alt="summary item" /></div>

            <div class="summary-info pl-16" fxLayout="column">
              <div class="headline text-bold">{{ summary.title }}</div>
              <ul class="summary-value">
                <li *ngFor="let item of summary.items">
                  <span>{{ item }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          *ngIf="data.contractualConditionsInformation.summaryList?.power?.length"
          fxLayout="row wrap"
          fxLayout.lt-md="column"
          class="summary-wrapper mt-24"
        >
          <div class="section-title blue">
            {{ data.contractualConditionsInformation.summaryListTitle.power }}
          </div>
          <div
            *ngFor="let summary of data.contractualConditionsInformation.summaryList.power"
            class="summary-item"
            fxFlex="50"
            fxFlex.lt-md="100"
            fxLayout="row"
          >
            <div fxLayout="column"><img [src]="summary.image" class="small-image" alt="summary item" /></div>

            <div class="summary-info pl-16" fxLayout="column">
              <div class="headline text-bold">{{ summary.title }}</div>
              <ul class="summary-value">
                <li *ngFor="let item of summary.items">
                  <span>{{ item }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="terms-wrapper">
          <ppf-checkbox
            *ngFor="let checkbox of data.contractualConditionsInformation.termsList"
            class="pb-8 terms-checkbox"
          >
            <input [id]="checkbox.key" [formControlName]="checkbox.key" ppfCheckbox type="checkbox" />

            <div fxLayout="row" fxLayout.lt-md="column">
              <div *ngFor="let value of checkbox.list" class="term-composed-line">
                <span *ngIf="value.type === 'text'" class="text">{{ value.message }}</span>
                <a *ngIf="value.type === 'link'" [href]="value.url" target="_blank" class="link">{{ value.message }}</a>
              </div>
            </div>
          </ppf-checkbox>
        </div>

        <div
          class="card-footer mt-32"
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutGap="16px"
          fxLayoutAlign="space-between center"
        >
          <div class="dropdown-wrapper" fxLayout="row" fxLayoutGap="16px">
            <ppf-select
              *ngFor="let dropdown of data.contractualConditionsInformation.gasDropdownList"
              [formControlName]="dropdown.key"
              [label]="dropdown.title"
              [options]="dropdown.options"
              (selectChange)="onSelectChanged($event)"
              class="dropdown"
              overlayClass="custom-select-overlay"
            >
            </ppf-select>
          </div>
          <div class="dropdown-wrapper" fxLayout="row" fxLayoutGap="16px">
            <ppf-select
              *ngFor="let dropdown of data.contractualConditionsInformation.powerDropdownList"
              [formControlName]="dropdown.key"
              [label]="dropdown.title"
              [options]="dropdown.options"
              (selectChange)="onSelectChanged($event)"
              class="dropdown"
              overlayClass="custom-select-overlay"
            >
            </ppf-select>
          </div>
          <ppf-button
            *ngIf="getActionKey(data)"
            [title]="'Continuă'"
            [disabled]="contractTermsForm.invalid"
            (onClick)="saveForm()"
            type="fill"
          ></ppf-button>
        </div>
      </div>
    </ppf-card>
  </div>
</div>

<ng-template #loading class="loader">
  <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
</ng-template>
