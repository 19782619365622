import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@app/shared/services/utils';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BindingOfferInput, MasterBindingOfferInput } from '@app/stepper/interfaces';
import { COMMODITIES } from '@app/core/dictionary/dictionary';

@Component({
  selector: 'om-master-binding-offer',
  templateUrl: './master-binding-offer.component.html',
  styleUrls: ['./master-binding-offer.component.scss'],
})
export class MasterBindingOfferComponent implements OnInit {
  @Input() data$: Observable<MasterBindingOfferInput>;
  public bindingOfferData$: Observable<BindingOfferInput>;
  public selectedOffer = '';
  public commodities = COMMODITIES;

  constructor() {}

  ngOnInit(): void {
    Utils.scrollToTop();
    this.bindingOfferData$ = this.data$?.pipe(
      tap((data) => {
        if (
          data.selectedOffer.hasOwnProperty('bindingOfferInformation') &&
          data.selectedOffer.hasOwnProperty('powerBindingOfferInformation')
        ) {
          this.selectedOffer = COMMODITIES.ENERGY;
        } else if (data.selectedOffer.hasOwnProperty('bindingOfferInformation')) {
          this.selectedOffer = COMMODITIES.GAS;
        } else {
          this.selectedOffer = COMMODITIES.POWER;
        }
      }),
      map((data) => {
        return { ...data.selectedOffer, actionsFn: data.actionsFn, actions: data.actions };
      }),
    );
  }
}
