import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Offers, OffersInformation } from '@app/stepper/interfaces';
import { map } from 'rxjs/operators';
import { LoaderService } from '@app/shared/services/loader.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DocumentPreviewComponent } from '@app/document-preview/document-preview.component';
import { ModalService } from 'paperflow-web-components';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { Utils } from '@app/shared/services/utils';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'om-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
})
export class OffersComponent implements OnInit {
  @Input() data$: Observable<Offers>;
  offersData$: Observable<OffersInformation>;
  public loaderState$: Observable<{ loading: boolean }>;
  public continueAction: (id) => any;
  public continueActionObject = { label: '', name: '' };
  private modal: any;

  constructor(
    private loader: LoaderService,
    private storage: StorageMap,
    private modalService: ModalService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    Utils.scrollToTop();
    document.body.classList.add('enable-steps');
    this.route.queryParams.subscribe((params) => {
      if (params.processUuid && localStorage.getItem('redirected_after_login') === 'done') {
        localStorage.removeItem('redirected_after_login');
        document.body.classList.remove('hide-modal');
        localStorage.setItem('logged_in_process', params.processUuid);
      }
    });

    this.loaderState$ = this.loader.loader$;
    this.offersData$ = this.data$.pipe(
      map((data) => {
        if (data && data.actionsFn) {
          this.continueAction = data.actionsFn[Object.keys(data.actionsFn)[0]];
          this.continueActionObject = data.actions[0];
        }
        return data.offersInformation;
      }),
    );
    this.storage.set('modalOpened', false).subscribe();
    this.loader.setLoaderState({ loading: false });
  }

  doAction(offer): void {
    this.continueAction({ offers: { selectedOffer: offer.type } }).subscribe();
    this.googleAnalyticsService.eventEmitter(
      'General offers choice',
      'custom_events',
      'general_offers',
      `offer.title`,
      1,
    );
  }

  viewDocument(url): void {
    this.modal = this.modalService.show(
      DocumentPreviewComponent,
      {
        closeOnBackdropClick: true,
      },
      { url },
    );
  }
}
