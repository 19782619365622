import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { AppEventsService } from '@app/shared/services/app-events.service';
import { filter } from 'rxjs/operators';
import _ from 'lodash';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'om-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('inputRef')
  inputRef: ElementRef;

  constructor(
    private router: Router,
    private appEventsService: AppEventsService,
    private renderer: Renderer2,
    private platform: Platform,
    private oauthService: OAuthService,
  ) {}

  ngOnInit(): void {
    if (this.oauthService.state) {
      if (!localStorage.getItem('redirected_after_login')) {
        localStorage.setItem('redirected_after_login', 'done');
        this.router.navigateByUrl('/home/flx-process?processUuid=' + this.oauthService.state);
      }
    }

    if (localStorage.getItem('redirected_after_login')) {
      document.body.classList.add('hide-modal');
    }

    // this prevent keyboard from showing on IOS
    if (this.platform.IOS) {
      this.renderer.listen('window', 'touchstart', () => {
        const selectContainers = document.getElementsByTagName('ppf-select');

        if (selectContainers.length) {
          _.forEach(selectContainers, (item) => {
            const selectInput = item.getElementsByTagName('input');
            if (selectInput[0]) {
              selectInput[0].readOnly = true;
            }
          });
        }

        const datePickerInputs = document.getElementsByClassName('mat-datepicker-input');
        if (datePickerInputs.length) {
          _.forEach(datePickerInputs, (item) => {
            item.readOnly = true;
          });
        }
      });
    }

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((data) => {
      if (
        localStorage.getItem('logged_in_process') &&
        !(data as RouterEvent).url.includes(localStorage.getItem('logged_in_process') as any)
      ) {
        this.oauthService.logOut();
        localStorage.removeItem('logged_in_process');
      }

      this.appEventsService.setHomeLayoutFlags({ showProcess: (data as RouterEvent).url.includes('flx-process') });
    });
  }
}
