import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private loaderSubject = new BehaviorSubject<{ loading: boolean }>({ loading: false });
  readonly loader$ = this.loaderSubject.asObservable();

  setLoaderState(value: { loading: boolean; message?: string }): void {
    this.loaderSubject.next(value);
  }
}
