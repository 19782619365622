<ppf-card class="header">
  <div class="header-content">
    <a id="logo-utilio" (click)="goHome()">
      <img src="../../assets/utilio-logo.png" class="logo-icon cursor-pointer" alt="UTILIO logo" />
    </a>
    <a id="logo" href="https://www.omvpetrom.com/ro" target="_blank">
      <img src="../../assets/omvp-logo.png" class="logo-icon cursor-pointer" alt="OMVP logo" />
    </a>
  </div>
</ppf-card>
