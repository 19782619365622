import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AccordionModule,
  AvatarModule,
  ButtonModule,
  CardModule,
  ChipModule,
  HintModule,
  MessageModule,
  SpinnerModule,
  ModalModule,
  MenuModule,
  BadgeModule,
  ListModule,
  InputModule,
  SelectModule,
  SharedModule as PpfShared,
  ListItemModule,
  TooltipModule,
  DatePickerModule,
  CheckboxModule,
  RadioModule,
  SwitchModule,
  IconModule,
} from 'paperflow-web-components';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';

import { NguCarouselModule } from '@ngu/carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PreventDoubleClickDirective } from '@app/shared/directives/prevent-double-click.directive';
import { FlxLocalizationModule } from 'flowx-process-renderer';

@NgModule({
  imports: [CommonModule, DatePickerModule.forRoot()],
  declarations: [PreventDoubleClickDirective],
  exports: [
    FlexLayoutModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    ChipModule,
    SpinnerModule,
    BadgeModule,
    MessageModule,
    HintModule,
    ModalModule,
    ListModule,
    ListItemModule,
    OverlayModule,
    TooltipModule,
    PpfShared,
    InputModule,
    DatePickerModule,
    ReactiveFormsModule,
    FormsModule,
    AvatarModule,
    MenuModule,
    SelectModule,
    AccordionModule,
    RadioModule,
    SwitchModule,
    NguCarouselModule,
    IconModule,
    PdfViewerModule,
    SignaturePadModule,
    PreventDoubleClickDirective,
    FlxLocalizationModule,
  ],
  providers: [Overlay],
})
export class SharedModule {}
