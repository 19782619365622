import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BindingOfferInput, ConfiguredOffer, DocumentsList, IncludedServices } from '@app/stepper/interfaces';
import { Utils } from '@app/shared/services/utils';
import { ConsumptionPoint } from '@app/stepper/stepper.interface';
import * as copywrite from '../../../assets/global.json';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { ModalService } from 'paperflow-web-components';
import { TariffsModalComponent } from '@app/stepper/tarrifs-modal/tariffs-modal.component';
import { ExportFileService } from '@app/shared/services/export-file.service';
import { COMMODITIES } from '@app/core/dictionary/dictionary';

@Component({
  selector: 'om-binding-offer',
  templateUrl: './binding-offer.component.html',
  styleUrls: ['./binding-offer.component.scss'],
})
export class BindingOfferComponent implements OnInit {
  @Input() data$: Observable<BindingOfferInput>;
  public bindingOfferData$: Observable<BindingOfferInput>;
  public documentsData: DocumentsList;
  public consumptionPoints: Array<ConsumptionPoint>;
  public includedServices: Array<IncludedServices>;
  public configuredOffer: ConfiguredOffer;
  public texts = copywrite.bindingOffer;
  public tariffsTexts = copywrite.tariffs;
  public buttons: Array<{ name: string; label: string }> = [];
  private modal: any;
  public key = 'bindingOfferInformation';
  private continueAction: (params) => any;
  public commodities = COMMODITIES;

  constructor(
    private modalService: ModalService,
    private exportFileService: ExportFileService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.bindingOfferData$ = this.data$?.pipe(
      tap((data) => {
        if (data && data[this.key]) {
          this.documentsData = data[this.key].documents;
          this.consumptionPoints = data[this.key].consumptionPoints;
          this.includedServices = data[this.key].includedServices;
          this.configuredOffer = data[this.key].configuredOffer;
          this.buttons = data[this.key].buttons;
        }

        if (data && data.actionsFn) {
          this.continueAction = data.actionsFn[Object.keys(data.actionsFn)[0]];
        }
      }),
    );

    this.googleAnalyticsService.eventEmitter(
      'Binding offer page',
      'in_app_page_view',
      'binding_offer',
      'page_binding_offer',
      1,
    );
  }

  getActionKey(data): any {
    return Utils.getActionKey(data);
  }

  doAction(): void {
    const params =
      this.key === 'bindingOfferInformation'
        ? { userAcceptedOfferGas: 'accepted' }
        : { userAcceptedOfferPower: 'accepted' };
    this.continueAction(params).subscribe();
  }

  downloadPdf(url, title): void {
    this.exportFileService.downloadPdf(url, title);
  }

  openModal(cp, field): void {
    if (field.key !== this.tariffsTexts.title) {
      return;
    }

    this.modal = this.modalService.show(
      TariffsModalComponent,
      {
        closeOnBackdropClick: true,
      },
      { cp },
    );
  }
}
