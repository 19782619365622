<div *ngIf="consumptionPointsData$ | async as data; else loading">
  <div class="consumption-points-page">
    <div class="headline page-title">{{ data.title | flxLocalize }}</div>

    <div *ngIf="consumptionPoints?.length; else empty">
      <ppf-card *ngFor="let point of consumptionPoints" class="consumption-point">
        <div fxLayout="column">
          <img [src]="point.image" class="point-image" alt="point-image" />
        </div>
        <div class="consumption-info">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="4px">
              <div fxLayout="row">
                <h2 class="consumption-title headline mr-4">{{ point.title }}</h2>
                <div *ngIf="point.brandNewConsumptionPoint" class="no-history-label">
                  {{ texts.brandNewConsumptionPoint }}
                </div>
              </div>
              <div *ngIf="point.gas" class="tag gas-tag mr-4">{{ texts.gasTitle }}</div>
              <div *ngIf="point.energy" class="tag energy-tag">{{ texts.energyTitle }}</div>
            </div>
            <div class="actions" fxLayout="row" fxLayoutGap="24px">
              <ppf-icon (click)="handleEditAction(point)" class="edit-button mr-16" name="edit"></ppf-icon>
              <ppf-icon (click)="handleDeleteAction(point)" class="delete-button mr-16" name="remove"></ppf-icon>
            </div>
          </div>
          <div class="info-wrapper">
            <div *ngFor="let field of point.fields" fxLayout="column" class="info-field">
              <div class="key">{{ field.key }}</div>
              <div class="value">{{ field.value }}</div>
            </div>
          </div>
          <div *ngIf="point.gas">
            <h3 class="subtitle gas">
              {{ texts.gasTitle }}
            </h3>
            <div class="info-wrapper">
              <div *ngFor="let field of point.gas.fields" fxLayout="column" class="info-field">
                <div class="key">{{ field.key }}</div>
                <div class="value">{{ field.value }}</div>
              </div>
            </div>
          </div>
          <div *ngIf="point.energy">
            <h3 class="subtitle energy">
              {{ texts.energyTitle }}
            </h3>
            <div class="info-wrapper">
              <div *ngFor="let field of point.energy.fields" fxLayout="column" class="info-field">
                <div class="key">{{ field.key }}</div>
                <div class="value">{{ field.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </ppf-card>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
        <ppf-button [title]="data.addButtonText | flxLocalize" type="border" class="mt-16" (onClick)="handleAddPoint()"></ppf-button>
        <ppf-button
          [disabled]="!checkCanContinue()"
          [ppfTooltip]="texts.tooltipFirstText"
          [tooltipColor]="!checkCanContinue() ? 'default' : 'custom'"
          [title]="data.continueButtonText | flxLocalize"
          type="fill"
          class="mt-16"
          omPreventDoubleClick
          (throttledClick)="handleRequest(data)"
        ></ppf-button>
      </div>
    </div>
  </div>

  <div
    *ngIf="data.bottomInfo"
    fxLayout="row"
    fxLayoutAlign="start start"
    class="info-wrapper info-container pl-32 mt-32"
  >
    <ppf-icon name="info" class="info-icon mr-24"></ppf-icon>
    <div class="info-wrapper">
      <p *ngFor="let info of data.bottomInfo">
        {{ info | flxLocalize }}
      </p>
    </div>
  </div>

  <ng-template #empty>
    <div class="empty-state-container" fxLayout="column" fxLayoutAlign="center center">
      <ppf-icon name="gray-pin" class="empty-state-icon pb-24"></ppf-icon>
      <div class="empty-state-text pb-24">{{ data.emptyStateText | flxLocalize }}</div>
      <ppf-button
        [title]="data.addButtonText | flxLocalize"
        type="fill"
        omPreventDoubleClick
        (throttledClick)="handleAddPoint()"
      ></ppf-button>
    </div>
  </ng-template>
</div>

<ng-template #loading class="loader">
  <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
</ng-template>

<ng-container *ngIf="loaderState$ | async as loaderState">
  <div *ppfShowModal="loaderState.loading">
    <ppf-spinner backgroundColor="#ffffff" borderColor="transparent" visualType="custom" size="custom" [radius]="90">
      <div *ngIf="loaderState.message" class="spinner-message">
        {{ loaderState.message | flxLocalize }}
      </div>
    </ppf-spinner>
  </div>
</ng-container>
