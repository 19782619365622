import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from '@app/shared/services/loader.service';
import { ToastService } from 'paperflow-web-components';
import * as copywrite from '@assets/global.json';
import { Utils } from '@app/shared/services/utils';

@Component({
  selector: 'om-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss'],
})
export class UploadDocumentsComponent implements OnInit {
  @Input() data$: Observable<any>;
  public uploadDocumentsData$: Observable<any>;
  public loaderState$: Observable<{ loading: boolean }>;
  private notifyUpload: (documentObject: {
    documents: { selectedDocFieldId: number; fileName: string; size: number };
  }) => any;
  private uploadDocumentAction: (formData) => any;
  private saveDocumentsAction: () => any;
  private deleteAction: (documentObject: { documents: { id: number; customId: string; documentType: string } }) => any;
  public texts = copywrite.uploadDocuments;
  public acceptedFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];

  constructor(private loader: LoaderService, private toastService: ToastService) {}

  ngOnInit(): void {
    Utils.scrollToTop();
    this.loaderState$ = this.loader.loader$;

    this.uploadDocumentsData$ = this.data$?.pipe(
      tap((data) => {
        if (!data) {
          return;
        }

        this.loader.setLoaderState({ loading: false });

        this.notifyUpload = data.actionsFn?.notify_upload;
        this.uploadDocumentAction = data.actionsFn?.upload_document;
        this.saveDocumentsAction = data.actionsFn?.save_form_documents;
        this.deleteAction = data.actionsFn?.delete_document;
      }),
    );
  }

  isDisabled(data): boolean {
    let hasDocument = false;
    data.uploadDocumentsData?.fields?.some((item) => {
      hasDocument = !!item.document;
      return !hasDocument;
    });

    return !hasDocument;
  }

  upload(event: any, id): void {
    if (!event?.target?.files) {
      return;
    }

    const files = event.target.files;

    if (!this.acceptedFileTypes.includes(files[0].type)) {
      this.toastService.show({ messageType: 'error', message: this.texts.type });
      return;
    }

    if (files[0].size > 10485760) {
      this.toastService.show({ messageType: 'error', message: this.texts.limit });
      return;
    }

    this.loader.setLoaderState({ loading: true });

    this.notifyUpload({
      documents: {
        selectedDocFieldId: id,
        fileName: files[0].name,
        size: files[0].size,
      },
    }).subscribe();

    const formData = new FormData();
    formData.append('file', files[0]);

    this.uploadDocumentAction(formData).subscribe();
  }

  deleteUploadedDocument(document): void {
    this.deleteAction({
      documents: {
        id: document.id,
        customId: document.customId,
        documentType: document.documentType,
      },
    }).subscribe();
  }

  doAction(): void {
    this.saveDocumentsAction().subscribe();
  }
}
