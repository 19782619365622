import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppEventsService {
  private appEventSubject = new BehaviorSubject<{ showProcess: boolean }>({
    showProcess: false,
  });
  readonly appEvent$ = this.appEventSubject.asObservable();

  setHomeLayoutFlags(value: { showProcess: boolean }): void {
    this.appEventSubject.next(value);
  }
}
