<ppf-modal *ngIf="data$ | async as data; else loading" class="omc-modal error-handling-modal">
  <ppf-card *ngIf="data.errorHandling" class="p-32">
    <div fxLayout="column" fxLayoutAlign="center center">
      <img [src]="'../assets/images/error-handling.svg'" alt="Error image" class="image" />

      <h2 class="title font-roboto text-center mt-24">{{ data.errorHandling.title }}</h2>
      <p class="mt-8 text-center">{{ data.errorHandling.subtitle }}</p>
    </div>
    <div *ngIf="data.actionsFn.log_out" fxLayout="row" fxLayoutAlign="center center" class="mt-24">
      <ppf-button
        [title]="getActionLabel(data.errorHandling)"
        (onClick)="doAction(data)"
        class="mt-32"
        type="border"
      ></ppf-button>
    </div>
  </ppf-card>
</ppf-modal>

<ng-template #loading class="loader">
  <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
</ng-template>
