import { Component, OnInit, Inject } from '@angular/core';
import { MODAL_DATA } from 'paperflow-web-components';
import { ModalService } from 'paperflow-web-components';

import * as copywrite from '../../../assets/global.json';
import { ConsumptionPoint } from '@app/stepper/stepper.interface';

@Component({
  selector: 'om-tariff-modal',
  templateUrl: './tariffs-modal.component.html',
  styleUrls: ['./tariffs-modal.component.scss'],
})
export class TariffsModalComponent implements OnInit {
  constructor(@Inject(MODAL_DATA) public data: { cp: ConsumptionPoint }, private modalService: ModalService) {}

  public cp: ConsumptionPoint;
  public texts = copywrite.tariffs;

  ngOnInit(): void {
    if (this.data) {
      this.cp = this.data.cp;
    }
  }

  closeModal(): void {
    this.modalService.hide();
  }
}
