<div class="info-modal-wrapper">
  <ppf-card class="info-modal">
    <div fxLayout="row" fxLayoutAlign="end center" class="title">
      <button class="modal-close" (click)="hideModal()">
        <ppf-icon name="close"></ppf-icon>
      </button>
    </div>
    <div class="card-header headline mb-24">{{ texts.title }}</div>
    <div class="card-content mb-24">
      <div class="question">{{ texts.content }}</div>
    </div>
  </ppf-card>
</div>
