import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StepperModule } from './stepper/stepper.module';

import { environment } from '../environments/environment';

import { LoaderHandlerComponent } from './loader-handler/loader-handler.component';

import { AuthInterceptor } from '@app/core/auth/auth.interceptor';
import { ErrorInterceptor } from '@app/core/error.interceptor';

import { success, error, close, IconModule } from 'paperflow-web-components';
import {
  arrowLeft,
  arrowRight,
  sign,
  edit,
  remove,
  question,
  grayPin,
  smallDoc,
  smallSign,
  download,
  smallPin,
  magnifier,
} from './shared/constants/icons';

@NgModule({
  declarations: [AppComponent, LoaderHandlerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    StepperModule,
    StoreModule.forRoot({}, { runtimeChecks: { strictStateImmutability: true } }),
    HttpClientModule,
    IconModule.forRoot({
      icons: [
        success,
        error,
        close,
        arrowLeft,
        arrowRight,
        sign,
        edit,
        remove,
        question,
        grayPin,
        smallDoc,
        smallSign,
        download,
        smallPin,
        magnifier,
      ],
    }),
  ],
  providers: [
    HttpClient,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: 'BASE_API_URL', useValue: environment.baseUrl },
    { provide: 'PROCESS_API_PATH', useValue: environment.processApiPath },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
