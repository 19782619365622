<div *ngIf="bindingOfferData$ | async as data; else loading" class="full-binding-offer-page" fxLayout="column">
  <div fxLayout="row" class="header-wrapper" fxLayoutAlign="space-between center">
    <div class="headline page-title">
      {{ texts.title }}
    </div>
  </div>

  <ppf-card *ngFor="let item of keys" class="page-card mb-16">
    <div fxLayout="row" class="card-content">
      <div class="offer-image card-image mr-16">
        <img [src]="data[item.key]?.configuredOffer?.image" class="image" alt="documents-signing" />
      </div>

      <div fxLayout="column">
        <h2
          class="title"
          [ngClass]="{
            green: item.key === 'bindingOfferInformation',
            blue: item.key === 'powerBindingOfferInformation'
          }"
        >
          {{ data[item.key]?.subtitle }}
        </h2>

        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="12px"
          class="product-fields mt-16"
        >
          <div
            *ngFor="let field of data[item.key]?.configuredOffer?.fields"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center center"
            class="field-container pl-8"
          >
            <div *ngIf="field?.values?.length" fxLayout="row" fxLayoutAlign="center center" class="pb-4">
              <span class="field-key text-center"> {{ field.title }}</span>
              <ppf-icon
                *ngIf="field.tooltipText"
                name="question"
                class="ml-4 field-icon"
                [ppfTooltip]="field.tooltipText"
              ></ppf-icon>
            </div>
            <div *ngFor="let value of field.values" fxLayoutAlign="center center">
              <div [ngClass]="field.valueClass" class="field-value text-center" [innerHTML]="value"></div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="16px" class="mt-24">
          <div fxLayout="row" fxLayoutAlign="start start" class="info-container">
            <ppf-icon name="info" class="info-icon mr-16"></ppf-icon>
            <div class="info-wrapper">
              <p>Pentru a putea continua procesul de contractare va trebui să accepți sau să refuzi această ofertă.</p>
            </div>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="12px" fxLayoutAlign.lt-sm="center center">
            <ppf-button
              leftIconName="close"
              class="ml-16 mr-16"
              [title]="data[item.key]?.buttonLabels[0]"
              [type]="item.acceptance === 'rejected' ? 'fill' : 'border'"
              (click)="item.acceptance = 'rejected'"
            ></ppf-button>
            <ppf-button
              leftIconName="checkmark"
              [title]="data[item.key]?.buttonLabels[1]"
              [type]="item.acceptance === 'accepted' ? 'fill' : 'border'"
              (click)="item.acceptance = 'accepted'"
            ></ppf-button>
          </div>
        </div>
        <div *ngIf="item.showDetails" class="details-container mt-16">
          <div *ngFor="let point of data[item.key].consumptionPoints" class="details-card">
            <div class="card-image" fxLayout="column">
              <img [src]="point.image" class="image" alt="general terms and conditions" />
            </div>
            <div class="consumption-info">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <h2 class="consumption-title headline">{{ point.title }}</h2>
                <div *ngIf="point.address" class="address-wrapper" fxLayout="row">
                  <ppf-icon class="map-icon" name="small-pin"></ppf-icon>
                  <span class="address">{{ point.address }}</span>
                </div>
              </div>
              <div *ngIf="point?.fields?.length" class="info-wrapper">
                <div *ngFor="let field of point.fields" fxLayout="column" class="info-field">
                  <div class="key">{{ field.key }}</div>
                  <div class="value">
                    <span
                      [ngClass]="{ 'highlighted': field.main, 'open-modal': field.key === tariffsTexts.title }"
                      (click)="openModal(point, field)"
                    >
                      {{ field.value }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="data[item.key].documents" class="details-card">
            <div class="card-image" fxLayout="column">
              <img [src]="data[item.key].documents.image" class="image mr-16" alt="" />
            </div>
            <div>
              <h2 class="title pb-16 headline">{{ data[item.key].documents.title }}</h2>
              <div class="subtitle">{{ data[item.key].documents.subtitle }}</div>

              <div class="documents-wrapper">
                <div *ngFor="let document of data[item.key].documents.list" class="document" fxLayout="row">
                  <ppf-icon name="small-doc" class="document-icon mt-4 pr-8"></ppf-icon>
                  <a [href]="document.url" target="_blank" download>{{ document.label }}</a>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              data[item.key].includedServices &&
              data[item.key].includedServices.length &&
              item.key === 'bindingOfferInformation'
            "
            class="details-card"
            fxLayout.lt-sm="column"
          >
            <div *ngFor="let service of data[item.key]?.includedServices" fxLayout="row">
              <div *ngIf="service.image" class="card-image mr-16">
                <img [src]="service.image" class="image" alt="service-image" />
              </div>
              <div [ngClass]="{ 'summary-align-sm': !service.image }">
                <h2 class="title pb-16 headline">{{ service.title }}</h2>
                <div class="summary-wrapper">
                  <div *ngFor="let item of service.services" class="summary-item">
                    <div class="summary-info" fxLayout="column">
                      <ul class="headline text-bold summary-value">
                        <li>
                          <div class="headline text-bold">{{ item.title }}</div>
                        </li>
                      </ul>
                      <span class="summary-message">{{ item.message }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          fxLayout="row"
          class="mt-16"
          fxLayout.lt-sm="column"
          fxLayoutGap.lt-sm="12px"
          fxLayoutAlign.lt-sm="center center"
        >
          <div class="download-wrapper" fxLayout="row">
            <ppf-icon name="magnifier" class="download-icon pr-8"></ppf-icon>
            <a (click)="item.showDetails = !item.showDetails">{{
              item.showDetails ? texts.hideDetailsAction : texts.showDetailsAction
            }}</a>
          </div>
          <div *ngIf="data[item.key]?.offerUrl" class="download-wrapper" fxLayout="row">
            <ppf-icon name="download" class="download-icon pr-8"></ppf-icon>
            <a
              (click)="
                downloadPdf(
                  data[item.key].offerUrl,
                  texts.docTitle + (item.key === 'powerBindingOfferInformation' ? ' Power' : ' Gas')
                )
              "
              >{{ texts.downloadAction }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </ppf-card>

  <div class="card-footer mt-32" fxLayoutAlign.lt-md="center" fxLayoutAlign="end">
    <ppf-button
      *ngIf="getActionKey(data)"
      [title]="'Continuă cu oferta acceptată'"
      [disabled]="isDisabled"
      type="fill"
      omPreventDoubleClick
      (throttledClick)="doAction(data)"
    ></ppf-button>
  </div>

  <div fxLayout="row" fxLayoutAlign="start start" class="info-wrapper info-container pl-32 mt-32">
    <ppf-icon name="info" class="info-icon mr-16"></ppf-icon>
    <div class="info-wrapper">
      <div *ngFor="let item of keys; let i = index" [ngClass]="{ 'mb-48': i < keys.length - 1 }">
        <p *ngFor="let info of data[item.key]?.bottomInfo">
          {{ info }}
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #loading class="loader">
  <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
</ng-template>
