<ppf-modal *ngIf="modalData$ | async as data; else loading" class="om-modal recap-modal">
  <ppf-card *ngIf="data.recapInformation" class="pl-64 pr-64">
    <div fxLayout="row" fxLayoutAlign="end center">
      <button class="modal-close" (click)="goToHome()">
        <ppf-icon name="close"></ppf-icon>
      </button>
    </div>
    <div fxLayout="row" class="mt-16" fxLayoutAlign="center center">
      <img [src]="data.recapInformation.image" alt="Welcome Image" class="modal-header-image" />
    </div>
    <div>
      <h2 class="modal-title mt-24 mb-8 text-center">{{ data.recapInformation.title | flxLocalize }}</h2>
      <p class="text-center">{{ data.recapInformation.subtitle | flxLocalize }}</p>

      <div
        *ngIf="data.recapInformation.benefits && data.recapInformation.benefits.length"
        class="benefits-box info-container mt-24 mb-24"
        fxLayout="column"
        fxLayoutGap="16px"
      >
        <div *ngFor="let benefit of data.recapInformation.benefits" class="benefit" fxLayout="row">
          <img [src]="benefit.image" alt="C2 image" class="benefit-image" />
          <div class="benefit-box" fxLayout="column" fxLayoutAlign="center start">
            <div class="info-title">{{ benefit.title | flxLocalize }}</div>
            <div class="info-subtitle">{{ benefit.subtitle | flxLocalize }}</div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-around center" class="mb-24">
        <ppf-button
          *ngIf="continueAction"
          class="go-to-button mr-16"
          [title]="texts.loginButton"
          (click)="login()"
        ></ppf-button>
        <ppf-button
          class="go-to-button mr-16"
          [title]="data.recapInformation.label | flxLocalize"
          type="border"
          omPreventDoubleClick
          (throttledClick)="goToAction()"
        ></ppf-button>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" class="mb-24">
        <img
          src="../assets/images/app-store.svg"
          alt="App Store"
          class="app-store-image mr-16"
          (click)="goTo('https://www.apple.com/app-store/', 'appstore')"
        />
        <img
          src="../assets/images/google-play.svg"
          alt="Google Play"
          class="google-play-image"
          (click)="goTo('https://play.google.com/', 'googleplay')"
        />
      </div>
    </div>
  </ppf-card>
</ppf-modal>

<ng-template #loading class="loader">
  <ppf-spinner borderColor="transparent" visualType="custom" size="custom" [radius]="90"></ppf-spinner>
</ng-template>
