import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'paperflow-web-components';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import * as copywrite from '@assets/global.json';
import { EmailValidationInformation, EmailValidationModalInput } from '@app/stepper/interfaces';
import { LoaderService } from '@app/shared/services/loader.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'om-email-validation-modal',
  templateUrl: './email-validation-modal.component.html',
  styleUrls: ['./email-validation-modal.component.scss'],
})
export class EmailValidationModalComponent implements OnInit {
  @Input() data$: Observable<EmailValidationModalInput>;
  public emailForm: FormGroup = new FormGroup({});
  public validationCodeForm: FormGroup = new FormGroup({});
  public emailValidationData$: Observable<EmailValidationInformation>;
  public loaderState$: Observable<{ loading: boolean; message?: string }>;
  public termsRead = false;
  public isCodeValid: any = false;
  public texts = copywrite.emailValidationModal;
  public isDirty = false;
  public spamTimer: number;
  private continueAction: () => any;
  private resendAction: (formValues) => any;
  private validateAction: (formValues) => any;
  private emailFormSubmitted = false;
  private validationCodeFormSubmitted = false;
  private loaderMessage = '';
  private validationError = '';
  private inputTouched = false;
  private emailSet = false;

  ngOnInit(): void {
    this.loaderState$ = this.loader.loader$;
    this.emailValidationData$ = this.data$.pipe(
      map((data) => {
        if (data && data.actionsFn && data.actions && data.actions.length) {
          this.resendAction = data.actionsFn.request_code;
          this.validateAction = data.actionsFn.validate;
          this.continueAction = data.actionsFn.continue;
        }
        return data.emailValidationInformation;
      }),
      tap((data) => {
        this.isCodeValid = data?.isCodeValid || false;
        this.loaderMessage = data.loaderMessage || this.loaderMessage;
        this.validationError = data.validationError || this.validationError;

        if (!this.isCodeValid && this.validationCodeForm.controls.validationCode && !this.validationCodeForm.pristine) {
          this.validationCodeForm.controls.validationCode.setErrors({
            rejected: true,
            message: this.validationError,
          });
        } else {
          this.createForms(data ? data.customerEmailAddress : '');
        }
      }),
    );
    this.loader.setLoaderState({ loading: false, message: '' });
    this.googleAnalyticsService.eventEmitter(
      'Email verification modal',
      'in_app_page_view',
      'email_validation',
      'modal_email',
      1,
    );
  }

  constructor(
    private modalService: ModalService,
    private loader: LoaderService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {}

  createForms(email): void {
    this.emailForm.addControl(
      'email',
      new FormControl('', [Validators.required, Validators.pattern(/^\S+@\S+\.\S+$/)]),
    );
    this.validationCodeForm.addControl(
      'validationCode',
      new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern('[0-9]*'),
      ]),
    );
    if (email && !this.emailSet) {
      this.emailForm.get('email')?.setValue(email);
      this.emailSet = true;
    }
  }

  showEmailFormErrors(control: AbstractControl): boolean {
    return !!(this.emailFormSubmitted && control.errors);
  }

  showSecurityCodeRejectErrors(control: AbstractControl): boolean {
    return !!(this.validationCodeFormSubmitted && !this.isCodeValid && control.errors && control.errors.rejected);
  }

  showSecurityCodeFormErrors(control: AbstractControl): boolean {
    return !!(this.validationCodeFormSubmitted && control.errors && !control.errors.rejected);
  }

  doResendAction(): void {
    this.emailFormSubmitted = true;

    if (!this.emailForm.valid) {
      return;
    }

    this.spamTimer = 10;

    this.isDirty = true;
    this.resendAction({ email: this.emailForm.value.email }).subscribe(() => {
      const interval = setInterval(() => {
        this.spamTimer--;

        if (!this.spamTimer) {
          clearInterval(interval);
        }
      }, 1000);
    });
    this.googleAnalyticsService.eventEmitter(
      'Send validation code',
      'custom_events',
      'email_validation',
      'send_validation_code',
      1,
    );
  }

  doValidateAction(): void {
    this.validationCodeFormSubmitted = true;

    if (!this.validationCodeForm.valid) {
      return;
    }

    this.validateAction({ validationCode: this.validationCodeForm.value.validationCode }).subscribe(() => {
      setTimeout(() => {
        if (this.isCodeValid) {
          this.doContinueAction();
        }
      }, 3000);
    });
    this.googleAnalyticsService.eventEmitter('Validate code', 'custom_events', 'email_validation', 'validate_code', 1);
  }

  doContinueAction(): void {
    this.modalService.hide();
    this.loader.setLoaderState({ loading: true, message: this.loaderMessage });
    this.continueAction().subscribe();
    this.googleAnalyticsService.eventEmitter(
      'Validation continue',
      'custom_events',
      'email_validation',
      'email_validation_continue',
      1,
    );
  }

  onInputTouched(): void {
    if (this.inputTouched) {
      return;
    }
    this.inputTouched = true;
    this.googleAnalyticsService.eventEmitter(
      'Input email address',
      'custom_events',
      'email_validation',
      'input_email_address',
      1,
    );
  }

  onUrlClicked(): void {
    this.googleAnalyticsService.eventEmitter(
      'Terms validation code',
      'custom_events',
      'email_validation',
      'terms_validation_code',
      1,
    );
  }

  get isDisabled(): boolean {
    return !this.termsRead || !this.isCodeValid;
  }
}
