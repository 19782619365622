<ppf-modal *ngIf="emailValidationData$ | async as data" class="email-validation-modal om-modal">
  <ppf-card class="email-validation-card">
    <div fxLayout="row" fxLayoutAlign="center center">
      <img [src]="'../assets' + data.image" alt="Welcome Image" class="envelope-image" />
    </div>
    <div>
      <h2 class="modal-title mt-24 mb-8 text-center">{{ data.title | flxLocalize }}</h2>
      <p class="text-center">{{ data.message | flxLocalize }}</p>
      <div [formGroup]="emailForm" class="email-form pt-16">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="center center">
          <div class="pr-16 pb-32 control-container" fxFlex>
            <ppf-input
              [label]="texts.emailPlaceholder"
              [ngClass]="{ invalid: showEmailFormErrors(emailForm.controls.email) }"
            >
              <input id="email" formControlName="email" ppfInput />
            </ppf-input>
            <div *ngIf="showEmailFormErrors(emailForm.controls.email)" class="form-errors">
              <ppf-hint
                type="error"
                [message]="emailForm.controls.email.errors?.required ? texts.emailRequired : texts.emailInvalid"
              ></ppf-hint>
            </div>
          </div>
          <ppf-button
            *ngIf="isDirty"
            [title]="texts.resendActionLabel.dirty + (spamTimer ? ' (' + spamTimer + ')' : '')"
            [disabled]="!termsRead || !!spamTimer"
            type="border"
            class="mb-24 go-to-button"
            (onClick)="doResendAction()"
          >
          </ppf-button>
        </div>
      </div>
      <ppf-checkbox *ngIf="data.terms && !isDirty" class="privacy-checkbox">
        <input id="email_validation_terms" ppfCheckbox [(ngModel)]="termsRead" type="checkbox" />
        <div fxLayout="row" fxLayout.lt-sm="column">
          <span class="no-wrap">{{ data.terms.label | flxLocalize }}&nbsp;</span>
          <a [href]="data.terms.url | flxLocalize" class="link" target="_blank" (click)="onUrlClicked()">{{ data.terms.urlText | flxLocalize }}</a>
        </div>
      </ppf-checkbox>
      <div *ngIf="isDirty" [formGroup]="validationCodeForm" class="email-form pt-16">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="center center">
          <div class="pr-16 pb-24 control-container">
            <ppf-input
              [label]="texts.validationCodePlaceholder"
              [ngClass]="{
                invalid:
                  showSecurityCodeFormErrors(validationCodeForm.controls.validationCode) ||
                  showSecurityCodeRejectErrors(validationCodeForm.controls.validationCode)
              }"
              [status]="isCodeValid ? 'success' : isCodeValid"
            >
              <input
                ppfInput
                id="validationCode"
                type="text"
                formControlName="validationCode"
                (input)="onInputTouched()"
              />
            </ppf-input>
            <div *ngIf="showSecurityCodeFormErrors(validationCodeForm.controls.validationCode)" class="form-errors">
              <ppf-hint type="error" [message]="texts.validationCodeError"></ppf-hint>
            </div>
            <div *ngIf="showSecurityCodeRejectErrors(validationCodeForm.controls.validationCode)" class="form-errors">
              <ppf-hint type="error" [message]="validationCodeForm.controls.validationCode.errors?.message | flxLocalize"></ppf-hint>
            </div>
            <div *ngIf="isCodeValid" class="form-errors">
              <ppf-hint type="info" class="succes" [message]="texts.codeValid"></ppf-hint>
            </div>
          </div>
          <ppf-button
            [title]="texts.validateActionLabel"
            [disabled]="isCodeValid"
            class="mb-24 go-to-button"
            (onClick)="doValidateAction()"
          ></ppf-button>
        </div>
      </div>
    </div>
    <div *ngIf="!isDirty" fxLayout="row" fxLayoutAlign="center center" class="mt-32 pl-16 pr-16">
      <ppf-button
        [title]="texts.resendActionLabel.initial"
        [disabled]="!termsRead"
        type="fill"
        class="mb-24 go-to-button"
        (onClick)="doResendAction()"
      >
      </ppf-button>
    </div>
  </ppf-card>
</ppf-modal>

<ng-container *ngIf="loaderState$ | async as loaderState">
  <div *ppfShowModal="loaderState.loading">
    <ppf-spinner backgroundColor="#ffffff" borderColor="transparent" visualType="custom" size="custom" [radius]="90">
      <div *ngIf="loaderState.message" class="spinner-message">
        {{ loaderState.message | flxLocalize }}
      </div>
    </ppf-spinner>
  </div>
</ng-container>
