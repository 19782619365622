<div class="offers-container" fxLayout="column" *ngIf="offersData$ | async as data; else loading">
  <h3 class="offers-title headline pl-16">{{ data.title | flxLocalize }}</h3>
  <div class="mt-32" fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="start stretch">
    <ppf-card
      *ngFor="let offer of data.offers"
      fxFlex="auto"
      class="ml-8 mr-8 mb-16 offer-container"
      [ngClass]="{ disabled: data.userType && data.userType.includes(offer.type) }"
      fxLayout="column"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="column" fxLayoutAlign="center center" class="offer-info">
        <img [src]="'../assets' + offer.image" alt="Offer Image" class="image mb-12" />
        <h2 class="offer-title headline" [ngClass]="{ green: offer.type === 'GAS', blue: offer.type === 'POWER' }">
          {{ offer.title }}
        </h2>
        <div *ngFor="let field of offer.fields" class="field-container pt-16 pb-16">
          <div fxLayout="row" fxLayoutAlign="center center">
            <span class="field-key">{{ field.key }}</span>
            <ppf-icon
              *ngIf="field.tooltipText"
              name="question"
              class="ml-4 field-icon"
              [ppfTooltip]="field.tooltipText"
            ></ppf-icon>
          </div>
          <div *ngFor="let value of field.values">
            <div [ngClass]="field.valueClass" class="field-value" [innerHTML]="value"></div>
          </div>
        </div>
      </div>
      <div *ngIf="continueActionObject.name" fxLayout="row" fxLayoutAlign="center center" class="offer-action mt-20">
        <ppf-button
          [title]="offer.buttonText"
          type="border"
          omPreventDoubleClick
          (throttledClick)="doAction(offer)"
        ></ppf-button>
      </div>
    </ppf-card>

    <ppf-card
      *ngIf="data.fullOffer"
      class="ml-8 mr-8 mb-16 offer-container full-offer"
      fxLayout="column"
      fxLayoutAlign="space-between center"
      [ngClass]="{ disabled: data.userType }"
    >
      <div fxLayout="column" fxLayoutAlign="center center" class="offer-info">
        <img [src]="'../assets' + data.fullOffer.image" alt="Offer Image" class="image mb-12" />
        <h2 class="offer-title headline">{{ data.fullOffer.title }}</h2>
        <div *ngFor="let field of data.fullOffer.fields" class="field-container pt-16 pb-16">
          <div fxLayout="row" fxLayoutAlign="center center">
            <span class="field-key">{{ field.key }}</span>
            <ppf-icon
              *ngIf="field.tooltipText"
              name="question"
              class="ml-4 field-icon"
              [ppfTooltip]="field.tooltipText"
            ></ppf-icon>
          </div>
          <div *ngFor="let value of field.values">
            <div [ngClass]="field.valueClass" class="field-value" [innerHTML]="value"></div>
          </div>
        </div>
        <div class="message mt-24">{{ data.fullOffer.message }}</div>
        <div *ngIf="data.fullOffer.summary" class="summary-wrapper mt-24">
          <div class="headline text-bold mb-24">{{ data.fullOffer.summary.title }}</div>
          <ul class="summary-value">
            <li *ngFor="let item of data.fullOffer.summary.items" class="mb-12">
              <span>{{ item }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="continueActionObject.name" class="config-action" fxLayout="column" fxLayoutAlign="end center">
        <ppf-button
          [title]="data.fullOffer.buttonText"
          type="border"
          omPreventDoubleClick
          (throttledClick)="doAction(data.fullOffer)"
        ></ppf-button>
      </div>
    </ppf-card>
  </div>

  <ppf-card
    *ngIf="data.insurance"
    class="page-card insurance-card mb-16"
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign.lt-sm="center center"
  >
    <div class="card-image" fxLayout="column">
      <img [src]="'../assets' + data.insurance.image" class="image" alt="general terms and conditions" />
    </div>
    <div class="card-content pl-32">
      <div class="title pb-8 pt-8 headline">{{ data.insurance.title | flxLocalize }}</div>
      <div class="subtitle">
        {{ data.insurance.subtitle | flxLocalize  }}
        <a (click)="viewDocument(data.insurance.subtitleLink)" class="link pointer">{{
          data.insurance.subtitleLinkText | flxLocalize
          }}</a>
      </div>
    </div>
  </ppf-card>

  <div fxLayout="row" fxLayoutAlign="start start" class="info-wrapper info-container pl-32 mt-16">
    <ppf-icon name="info" class="info-icon mr-24"></ppf-icon>
    <div class="info-wrapper">
      <p *ngFor="let info of data.bottomInfo">
        {{ info | flxLocalize  }}
      </p>
    </div>
  </div>
  <p class="disclaimer pl-16 mt-24">{{ data.disclaimer | flxLocalize }}</p>
</div>
<ng-template #loading class="loader">
  <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
</ng-template>
