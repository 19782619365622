import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentsSigning } from '@app/stepper/interfaces';
import * as copywrite from '@assets/global.json';
import { DocumentPreviewComponent } from '@app/document-preview/document-preview.component';
import { Utils } from '@app/shared/services/utils';
import { SignaturePad } from 'angular2-signaturepad';
import { LoaderService } from '@app/shared/services/loader.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { ModalService } from 'paperflow-web-components';
import { DOC_TYPES } from '@app/core/dictionary/dictionary';

@Component({
  selector: 'om-documents-signing',
  templateUrl: './documents-signing.component.html',
  styleUrls: ['./documents-signing.component.scss'],
})
export class DocumentsSigningComponent implements OnInit {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @Input() data$: Observable<DocumentsSigning>;
  public documentsSigningData$: Observable<DocumentsSigning>;
  public documentsSigningForm = new FormGroup({});
  public loaderState$: Observable<{ loading: boolean; message?: string }>;
  public documentsData: any;
  public signatureData: any;
  public texts = copywrite.documentsSigning;
  private submitConsentAction: (form) => any;
  private modal: any;
  public signaturePadOptions: object = {
    minWidth: 1,
    canvasWidth: window.innerWidth - 80 < 442 ? window.innerWidth - 80 : 442,
    height: 132,
    backgroundColor: 'rgb(255, 255, 255)',
  };

  constructor(
    private modalService: ModalService,
    private loader: LoaderService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {}

  ngOnInit(): void {
    Utils.scrollToTop();
    this.loaderState$ = this.loader.loader$;

    this.documentsSigningData$ = this.data$?.pipe(
      tap((data) => this.createForm(data)),
      tap((data) => {
        if (!data) {
          return;
        }
        if (data.documentsSigningInformation && data.documentsSigningInformation.documents) {
          this.documentsData = {
            ...data.documentsSigningInformation.documents,
            gasList: data.documentsSigningInformation.documents.list?.filter(
              (doc) => doc.type === DOC_TYPES.GAS || !doc.type,
            ),
            powerList: data.documentsSigningInformation.documents.list?.filter((doc) => doc.type === DOC_TYPES.POWER),
          };
        }

        this.signatureData = data.documentsSigningInformation?.signature;
      }),
    );

    this.googleAnalyticsService.eventEmitter(
      'Documents signing page',
      'in_app_page_view',
      'document_signing',
      'page_documents_signing',
      1,
    );
  }

  createForm(data): void {
    if (data && data.actionsFn && data.actionsFn.sign_documents) {
      this.submitConsentAction = data.actionsFn.sign_documents;
    }

    this.documentsSigningForm.addControl('signature', new FormControl('', Validators.required));
  }

  saveForm(): void {
    this.loader.setLoaderState({ loading: true, message: this.texts.loaderText });

    this.submitConsentAction(this.documentsSigningForm.value).subscribe();
    this.googleAnalyticsService.eventEmitter(
      'Sign documents',
      'custom_events',
      'document_signing',
      'sign_documents',
      1,
    );
  }

  viewDocument(url, title): void {
    this.modal = this.modalService.show(
      DocumentPreviewComponent,
      {
        closeOnBackdropClick: true,
      },
      { url, title },
    );
  }

  drawComplete(): any {
    this.documentsSigningForm.setValue({ signature: this.signaturePad.toDataURL() });
  }

  resetSignature(): any {
    this.signaturePad.clear();
    this.documentsSigningForm.setValue({ signature: '' });
    this.googleAnalyticsService.eventEmitter(
      'Reset signature',
      'custom_events',
      'document_signing',
      'reset_signature',
      1,
    );
  }

  getActionKey(data): any {
    return Utils.getActionKey(data);
  }

  getActionLabel(data): string {
    return Utils.getActionLabel(data);
  }
}
