import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import _ from 'lodash';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoaderService } from '@app/shared/services/loader.service';
import { ErrorHandlingInput } from '@app/stepper/error-handling/interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'om-error-handling',
  templateUrl: './error-handling.component.html',
  styleUrls: ['./error-handling.component.scss'],
})
export class ErrorHandlingComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<ErrorHandlingInput>;
  public renderer: Renderer2;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private oauthService: OAuthService,
    private loader: LoaderService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.loader.setLoaderState({ loading: false });
  }

  getActionLabel(data: any): string {
    const labelArray = _.filter(data.buttons, (button) => button.value === 'log_out');
    return labelArray.length ? labelArray[0].label : '';
  }

  doAction(data): void {
    this.googleAnalyticsService.eventEmitter('Error occurred', 'custom_events', 'error_occurred', 'click', 1);
    data.actionsFn.log_out().subscribe(() => {
      if (data.errorHandling.needLogOut) {
        this.oauthService.logOut();
      }
      this.router.navigateByUrl('/home');
    });
  }

  ngOnDestroy(): void {
    document.body.classList.remove('no-scroll');
  }
}
