<ppf-modal class="tariffs-modal om-modal">
  <ppf-card>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="title mb-16">
      <h4 class="tariffs-title">{{ texts.title }}</h4>
      <button class="modal-close" (click)="closeModal()">
        <ppf-icon name="close"></ppf-icon>
      </button>
    </div>

    <div class="cp-card p-16 mb-8">
      <span>{{ cp.title }}</span>
    </div>
    <div *ngFor="let item of cp.tariffs" fxLayout="row" fxLayoutAlign="space-between center" class="tariff-item p-16">
      <div class="mr-12">{{ item.label }}</div>
      <div class="tariff-value">
        <span>{{ item.value }}</span>
        <span class="currency">
          {{ item.currency }}
        </span>
      </div>
    </div>
  </ppf-card>
</ppf-modal>
