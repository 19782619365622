import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BindingOfferInput } from '@app/stepper/interfaces';
import { Utils } from '@app/shared/services/utils';
import * as copywrite from '../../../assets/global.json';
import { LoaderService } from '@app/shared/services/loader.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { ModalService } from 'paperflow-web-components';
import { TariffsModalComponent } from '@app/stepper/tarrifs-modal/tariffs-modal.component';
import { ExportFileService } from '@app/shared/services/export-file.service';
import { ConfirmationModalComponent } from '@app/confirmation-modal/confirmation-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'om-energy-binding-offer',
  templateUrl: './energy-binding-offer.component.html',
  styleUrls: ['./energy-binding-offer.component.scss'],
})
export class EnergyBindingOfferComponent implements OnInit {
  @Input() data$: Observable<BindingOfferInput>;
  public bindingOfferData$: Observable<BindingOfferInput>;
  public texts = copywrite.bindingOffer;
  public tariffsTexts = copywrite.tariffs;
  private modal: any;
  public keys: Array<{ key: string; showDetails: boolean; acceptance: any }> = [
    { key: 'bindingOfferInformation', showDetails: false, acceptance: false },
    { key: 'powerBindingOfferInformation', showDetails: false, acceptance: false },
  ];

  constructor(
    private loader: LoaderService,
    private modalService: ModalService,
    private exportFileService: ExportFileService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.bindingOfferData$ = this.data$;

    this.googleAnalyticsService.eventEmitter(
      'Binding offer page',
      'in_app_page_view',
      'binding_offer',
      'page_binding_offer',
      1,
    );
  }

  get isDisabled(): boolean {
    return this.keys.filter((item) => !item.acceptance).length >= 1;
  }

  doAction(data): void {
    if (this.isDisabled) {
      return;
    }

    if (this.keys.filter((item) => item.acceptance === 'rejected').length === 2) {
      this.modal = this.modalService.show(
        ConfirmationModalComponent,
        {
          closeOnBackdropClick: false,
        },
        {
          callback: () => this.router.navigateByUrl('/home'),
          texts: copywrite.boConfirmationModal,
        },
      );
    } else {
      data.actionsFn[this.getActionKey(data)]({
        userAcceptedOfferGas: this.keys[0].acceptance,
        userAcceptedOfferPower: this.keys[1].acceptance,
      }).subscribe();
    }
  }

  getActionKey(data): any {
    return Utils.getActionKey(data);
  }

  downloadPdf(url, title): void {
    this.exportFileService.downloadPdf(url, title);
  }

  openModal(cp, field): void {
    if (field.key !== this.tariffsTexts.title) {
      return;
    }

    this.modal = this.modalService.show(
      TariffsModalComponent,
      {
        closeOnBackdropClick: true,
      },
      { cp },
    );
  }
}
