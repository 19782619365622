import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService } from 'paperflow-web-components';
import { Router } from '@angular/router';
import { CustomerModalInformation, CustomerModalInput } from '@app/stepper/interfaces';
import { COMMON_DICTIONARY } from '@app/shared/constants/stepper.constants';
import { LoaderService } from '@app/shared/services/loader.service';
import { map, tap } from 'rxjs/operators';
import { ActionMeta } from '@app/shared/interfaces';
import * as copywrite from '../../../assets/global.json';

@Component({
  selector: 'om-customer-two-modal',
  templateUrl: './customer-modal.component.html',
  styleUrls: ['./customer-modal.component.scss'],
})
export class CustomerModalComponent implements OnInit {
  @Input() data$: Observable<CustomerModalInput>;
  public modalData$: Observable<CustomerModalInformation>;
  public loaderState$: Observable<{ loading: boolean }>;
  public actionsMeta: Array<ActionMeta>;
  public actionsFnList: any;
  public texts = copywrite.customerModal;

  constructor(private modalService: ModalService, private router: Router, private loader: LoaderService) {}

  ngOnInit(): void {
    this.modalData$ = this.data$.pipe(
      map((data) => {
        if (data && data.actionsFn && data.actions && data.actions.length) {
          this.actionsFnList = data.actionsFn;
        }

        return data.creditRiskModalInformation;
      }),
      tap((data) => {
        this.actionsMeta = data?.actionsMetadata;
      }),
    );
    this.loaderState$ = this.loader.loader$;
    this.loader.setLoaderState({ loading: false });
  }

  doAction(key): void {
    if (key === COMMON_DICTIONARY.GO_HOME_KEY) {
      this.goToHome();
    } else {
      this.actionsFnList[key]().subscribe();
      this.modalService.hide();
    }
  }

  goToHome(): void {
    this.modalService.hide();
    this.router.navigateByUrl('/home');
  }
}
