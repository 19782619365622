import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExportFileService {
  constructor(private httpClient: HttpClient) {}

  downloadFile(data: any): void {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, 'myFile.csv');
  }

  downloadPdf(url: string, invoiceId: string): void {
    const downloadLink = document.createElement('a');

    this.toBlob(url).subscribe((state) => {
      downloadLink.href = state;
      downloadLink.download = (invoiceId || 'untitled') + '.pdf';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }

  toBlob(url): Observable<string> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map((data: any) => {
        return URL.createObjectURL(data);
      }),
    );
  }
}
