<div class="configure-offer-container" fxLayout="column" *ngIf="offerData$ | async as data; else loading">
  <div fxLayout="row" fxLayoutAlign="start center">
    <h3 class="headline">{{ data.title | flxLocalize }}</h3>
  </div>

  <div *ngIf="gasConfiguredOffer" fxLayout="row" fxLayout.lt-md="column" class="config-layout-wrapper pt-16 mt-32">
    <div fxLayout="column" fxLayoutAlign="start center" class="options-section" [formGroup]="gasConfigForm">
      <ppf-card *ngFor="let option of data.options.gas" class="option-card" fxLayout="row">
        <div class="option-image" fxLayout="column">
          <img [src]="'../assets' + option.image" alt="Option image" class="option-image" />
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" class="option-content-wrapper">
          <div class="option-content" fxFlex="1 1 100%">
            <div class="title headline">{{ option.title }}</div>
            <div class="message">{{ option.message }}</div>
          </div>
          <div class="option-action ml-16" fxLayout="row" fxLayoutAlign="end center">
            <ppf-switch *ngIf="option.type === CONFIG_OPTIONS.TYPE_SWITCH">
              <input
                ppfSwitch
                type="checkbox"
                (ngModelChange)="onSwitchChange($event, option, gasConfigForm, gasConfiguredOffer, gasPrices, true)"
                [formControlName]="option.key"
              />
            </ppf-switch>
            <ppf-select
              *ngIf="option.type === CONFIG_OPTIONS.TYPE_DROPDOWN"
              [formControlName]="option.key"
              [label]="option.selectLabel || ''"
              [options]="option.values || []"
              (ngModelChange)="onSelectChange($event, option, gasConfigForm, gasConfiguredOffer, gasPrices, true)"
              class="dropdown"
              overlayClass="custom-select-overlay"
            >
            </ppf-select>

            <div class="checkbox-wrapper">
              <span class="label-text">{{ option.checkboxLabel }}</span>
              <ppf-checkbox class="insurance-control" *ngIf="option.type === CONFIG_OPTIONS.TYPE_CHECKBOX">
                <input
                  [id]="option.key"
                  [formControlName]="option.key"
                  [value]="option.value"
                  (ngModelChange)="onSwitchChange($event, option, gasConfigForm, gasConfiguredOffer, gasPrices, true)"
                  ppfCheckbox
                  type="checkbox"
                />
              </ppf-checkbox>
            </div>
          </div>
        </div>
      </ppf-card>
    </div>

    <div class="product-section">
      <ppf-card *ngIf="gasConfiguredOffer" class="product-card" fxLayout="column" fxLayoutAlign="start center">
        <img [src]="'../assets' + gasConfiguredOffer.image" alt="Product image" class="image mb-12" />
        <div class="product-title headline green">{{ gasConfiguredOffer.title }}</div>

        <div class="product-fields">
          <div *ngFor="let field of gasConfiguredOffer.fields" class="field-container pb-16 pt-16">
            <div *ngIf="field?.values?.length" fxLayout="row" fxLayoutAlign="center center" class="pb-4">
              <span class="field-key">{{ field.title }}</span>
              <ppf-icon
                *ngIf="field.tooltipText"
                name="question"
                class="ml-4 field-icon"
                [ppfTooltip]="field.tooltipText"
              ></ppf-icon>
            </div>
            <div *ngFor="let value of field.values" fxLayoutAlign="center center">
              <div [ngClass]="field.valueClass" class="field-value" [innerHTML]="value"></div>
            </div>
          </div>
        </div>

        <div
          *ngIf="continueAction && gasConfiguredOffer && !powerConfiguredOffer"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="offer-action"
        >
          <ppf-button
            *ngIf="gasConfiguredOffer && !powerConfiguredOffer"
            title="Continuă"
            class="save-config-btn"
            type="fill"
            omPreventDoubleClick
            (throttledClick)="doAction()"
          ></ppf-button>
        </div>
      </ppf-card>
    </div>
  </div>

  <div *ngIf="powerConfiguredOffer">
    <div *ngIf="gasConfiguredOffer" class="divider mt-48"></div>
    <div fxLayout="row" fxLayout.lt-md="column" class="config-layout-wrapper pt-16 mt-32">
      <div fxLayout="column" fxLayoutAlign="start center" class="options-section" [formGroup]="powerConfigForm">
        <ppf-card *ngFor="let option of data.options.power" class="option-card" fxLayout="row">
          <div class="option-image" fxLayout="column">
            <img [src]="'../assets' + option.image" alt="Option image" class="option-image" />
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" class="option-content-wrapper">
            <div class="option-content" fxLayout="column">
              <div class="title headline">{{ option.title }}</div>
              <div class="message">{{ option.message }}</div>
            </div>
            <div class="option-action ml-16" fxLayout="row" fxLayoutAlign="end center">
              <ppf-switch *ngIf="option.type === CONFIG_OPTIONS.TYPE_SWITCH">
                <input
                  ppfSwitch
                  type="checkbox"
                  (ngModelChange)="
                    onSwitchChange($event, option, powerConfigForm, powerConfiguredOffer, powerPrices, false)
                  "
                  [formControlName]="option.key"
                />
              </ppf-switch>
              <ppf-select
                *ngIf="option.type === CONFIG_OPTIONS.TYPE_DROPDOWN"
                [formControlName]="option.key"
                [label]="option.selectLabel || ''"
                [options]="option.values || []"
                (ngModelChange)="
                  onSelectChange($event, option, powerConfigForm, powerConfiguredOffer, powerPrices, false)
                "
                class="dropdown"
                overlayClass="custom-select-overlay"
              >
              </ppf-select>

              <div class="checkbox-wrapper">
                <span class="label-text">{{ option.checkboxLabel }}</span>
                <ppf-checkbox class="insurance-control" *ngIf="option.type === CONFIG_OPTIONS.TYPE_CHECKBOX">
                  <input
                    [id]="option.key"
                    [formControlName]="option.key"
                    [value]="option.value"
                    (ngModelChange)="
                      onSwitchChange($event, option, powerConfigForm, powerConfiguredOffer, powerPrices, false)
                    "
                    ppfCheckbox
                    type="checkbox"
                  />
                </ppf-checkbox>
              </div>
            </div>
          </div>
        </ppf-card>
      </div>

      <div class="product-section">
        <ppf-card *ngIf="powerConfiguredOffer" class="product-card" fxLayout="column" fxLayoutAlign="start center">
          <img [src]="'../assets' + powerConfiguredOffer.image" alt="Product image" class="image mb-12" />
          <div class="product-title headline blue">{{ powerConfiguredOffer.title }}</div>

          <div class="product-fields">
            <div *ngFor="let field of powerConfiguredOffer.fields" class="field-container pb-16 pt-16">
              <div *ngIf="field?.values?.length" fxLayout="row" fxLayoutAlign="center center" class="pb-4">
                <span class="field-key">{{ field.title }}</span>
                <ppf-icon
                  *ngIf="field.tooltipText"
                  name="question"
                  class="ml-4 field-icon"
                  [ppfTooltip]="field.tooltipText"
                ></ppf-icon>
              </div>
              <div *ngFor="let value of field.values" fxLayoutAlign="center center">
                <div [ngClass]="field.valueClass" class="field-value" [innerHTML]="value"></div>
              </div>
            </div>
          </div>

          <div
            *ngIf="continueAction && !gasConfiguredOffer && powerConfiguredOffer"
            fxLayout="row"
            fxLayoutAlign="center center"
            class="offer-action"
          >
            <ppf-button
              title="Continuă"
              class="save-config-btn"
              type="fill"
              omPreventDoubleClick
              (throttledClick)="doAction()"
            ></ppf-button>
          </div>
        </ppf-card>
      </div>
    </div>
  </div>

  <div *ngIf="data.globalOptions && data.globalOptions.length">
    <div class="divider mt-48"></div>
    <div fxLayout="row" fxLayout.lt-md="column" class="config-layout-wrapper pt-16 mt-32">
      <div fxLayout="column" fxLayoutAlign="start center" class="options-section" [formGroup]="globalConfigForm">
        <ppf-card
          *ngFor="let option of data.globalOptions"
          class="option-card"
          fxLayout="row"
          fxLayout.lt-sm="column"
          fxLayoutAlign.lt-sm="center center"
        >
          <div class="option-image" fxLayout="column">
            <img [src]="'../assets' + option.image" alt="Option image" class="option-image" />
          </div>
          <div fxLayout="row" fxLayout.lt-md="column" class="option-content-wrapper">
            <div class="option-content" fxLayout="column">
              <div class="title headline">{{ option.title }}</div>
              <div class="message">{{ option.message }}</div>
            </div>
            <div class="option-action ml-16" fxLayout="row" fxLayoutAlign="end center">
              <div class="checkbox-wrapper">
                <span class="label-text">{{ option.checkboxLabel }}</span>
                <ppf-checkbox class="insurance-control" *ngIf="option.type === CONFIG_OPTIONS.TYPE_CHECKBOX">
                  <input
                    [id]="option.key"
                    [formControlName]="option.key"
                    [value]="option.value"
                    (ngModelChange)="
                      onSwitchChange($event, option, gasConfigForm, gasConfiguredOffer, gasPrices, true, true, true);
                      onSwitchChange(
                        $event,
                        option,
                        powerConfigForm,
                        powerConfiguredOffer,
                        powerPrices,
                        false,
                        false,
                        true
                      )
                    "
                    ppfCheckbox
                    type="checkbox"
                  />
                </ppf-checkbox>
              </div>
            </div>
          </div>
        </ppf-card>
      </div>

      <div class="product-section">
        <ppf-card class="product-card" fxLayout="column" fxLayoutAlign="start center">
          <img [src]="'../assets' + globalConfiguredOffer.image" alt="Product image" class="image mb-12" />
          <div class="product-title headline">{{ globalConfiguredOffer.title }}</div>

          <div class="product-fields">
            <div *ngFor="let field of globalConfiguredOffer.fields" class="field-container pb-16 pt-16">
              <div *ngIf="field?.values?.length" fxLayout="row" fxLayoutAlign="center center" class="pb-4">
                <span class="field-key">{{ field.title }}</span>
                <ppf-icon
                  *ngIf="field.tooltipText"
                  name="question"
                  class="ml-4 field-icon"
                  [ppfTooltip]="field.tooltipText"
                ></ppf-icon>
              </div>
              <div *ngFor="let value of field.values" fxLayoutAlign="center center">
                <div [ngClass]="field.valueClass" class="field-value" [innerHTML]="value"></div>
              </div>
            </div>
          </div>

          <div *ngIf="continueAction" fxLayout="row" fxLayoutAlign="center center" class="offer-action">
            <ppf-button
              title="Continuă"
              class="save-config-btn"
              type="fill"
              omPreventDoubleClick
              (throttledClick)="doAction()"
            ></ppf-button>
          </div>
        </ppf-card>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      (!data.globalOptions || !data.globalOptions.length) &&
      gasConfiguredOffer &&
      powerConfiguredOffer &&
      continueAction
    "
    fxLayout="row"
    fxLayoutAlign="center center"
    class="offer-action mt-32"
  >
    <ppf-button
      title="Continuă"
      class="save-config-btn"
      type="fill"
      omPreventDoubleClick
      (throttledClick)="doAction()"
    ></ppf-button>
  </div>

  <div
    *ngIf="data.bottomInfo"
    fxLayout="row"
    fxLayoutAlign="start start"
    class="info-wrapper info-container pl-32 ml-0 mr-0"
  >
    <ppf-icon name="info" class="info-icon mr-24"></ppf-icon>
    <div class="info-wrapper">
      <p *ngFor="let info of data.bottomInfo">
        {{ info }}
      </p>
    </div>
  </div>
  <p class="disclaimer pl-16 mt-24">{{ data.disclaimer }}</p>
</div>

<ng-template #loading class="loader">
  <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
</ng-template>
