export const CONFIG_OPTIONS = {
  CONTRACT_LENGTH_KEY: 'durata_contract',
  PAYMENT_SCHEDULE_KEY: 'termen_plata',
  TYPE_SWITCH: 'toggle',
  TYPE_DROPDOWN: 'dropdown',
  TYPE_CHECKBOX: 'checkbox',
};

export const CONFIGURED_OFFER = {
  ESTIMATED_COST_KEY: 'cost_estimat',
  FIXED_COST_KEY: 'pret_fix',
  SUBSCRIPTION_COST_KEY: 'pret_abonament',
  PRICE_AVAILABILITY_KEY: 'valabilitate_pret',
  PAYMENT_SCHEDULE_KEY: 'termen_plata',
  INCLUDED_SERVICES_KEY: 'abonamente_incluse',
};

export const OFFER_SERVICES = {
  TECH_SERVICE: 'Servicii tehnice',
  INSURANCE_SERVICE: 'Asigurare bunuri',
};

export const CONTRACTUAL_CONDITIONS = {
  TOC_AGREEMENT_KEY: 'toc_agreement',
  GDPR_AGREEMENT_KEY: 'gdpr_agreement',
};

export const COMMON_DICTIONARY = {
  GO_HOME_KEY: 'return_home',
  GO_TO_COLLABORATION_KEY: 'navigate_myaccount',
};
