<div class="header" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="center center">
    <span class="title mr-16">{{ docName }}</span>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    {{ texts.pagesLabel }}

    <input name="page" class="page-input" type="number" [(ngModel)]="currentPage" />
    <span>/{{ numberOfPages ? numberOfPages : '1' }}</span>
    <ppf-button
      [disabled]="currentPage === 1"
      (onClick)="currentPage = currentPage - 1"
      class="header-button ml-8 mr-8"
      type="flat"
      leftIconName="arrowLeft"
    >
    </ppf-button>
    <ppf-button
      [disabled]="currentPage === numberOfPages"
      (onClick)="currentPage = currentPage + 1"
      class="header-button"
      type="flat"
      leftIconName="arrowRight"
    >
    </ppf-button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <ppf-button
      *ngIf="docSrc$ | async as docSrc"
      class="header-button"
      (click)="downloadPdf(docSrc)"
      type="flat"
      leftIconName="download"
    ></ppf-button>
    <ppf-button
      class="header-button close-button ml-8"
      (click)="closeModal()"
      type="flat"
      leftIconName="close"
    ></ppf-button>
  </div>
</div>
<div *ngIf="docSrc$ | async as docSrc; else loading" fxLayout="row" fxLayoutAlign="center start" class="mt-24">
  <pdf-viewer
    *ngIf="docSrc"
    [src]="docSrc"
    [(page)]="currentPage"
    [show-all]="true"
    [fit-to-page]="true"
    (after-load-complete)="afterLoadComplete($event)"
  ></pdf-viewer>
</div>

<ng-template #loading>
  <ppf-spinner
    fxFlexFill
    fxLayout="column"
    fxLayoutAlign="center center"
    backgroundColor="#ffffff"
    borderColor="transparent"
    visualType="custom"
    size="custom"
    [radius]="90"
  ></ppf-spinner>
</ng-template>
