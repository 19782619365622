import { Component, Inject, OnInit } from '@angular/core';
import { MODAL_DATA } from 'paperflow-web-components';
import { ModalService } from 'paperflow-web-components';

@Component({
  selector: 'om-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  public action: (payload?) => any;
  public callback: (payload?) => void;
  public payload: any;
  public texts: any = {};

  constructor(@Inject(MODAL_DATA) public data, private modalService: ModalService) {}

  ngOnInit(): void {
    if (this.data) {
      this.payload = this.data.payload;
      this.action = this.data.action;
      this.callback = this.data.callback;
      this.texts = this.data.texts;
    }
  }

  handleAction(): void {
    if (this.payload) {
      if (this.action) {
        this.action(this.payload).subscribe();
      }
      if (this.callback) {
        this.callback(this.payload);
      }
    } else {
      if (this.action) {
        this.action().subscribe();
      }
      if (this.callback) {
        this.callback();
      }
    }
    this.modalService.hide();
  }

  handleCancel(): void {
    this.modalService.hide();
  }
}
