export class Utils {
  public static getActionKey(context): any {
    return context.actionsFn ? Object.keys(context.actionsFn)[0] : '';
  }

  public static getActionLabel(context): string {
    const action = context.actions && context.actions[0];
    return action && action.label ? action.label : '';
  }

  public static doAction(context, key): void {
    context.actionsFn[key]().subscribe();
  }

  public static scrollToTop(): void {
    const container = document.getElementById('appContainer');
    if (container) {
      container.scroll(0, 0);
    }
  }
}
