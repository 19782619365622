import { Component, OnInit } from '@angular/core';
import { BindingOfferComponent } from '@app/stepper/binding-offer/binding-offer.component';

@Component({
  selector: 'om-power-binding-offer',
  templateUrl: './binding-offer.component.html',
  styleUrls: ['./binding-offer.component.scss'],
})
export class PowerBindingOfferComponent extends BindingOfferComponent implements OnInit{
  public key = 'powerBindingOfferInformation';
}
