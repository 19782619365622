import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConsumptionPoint, ConsumptionPoints, ConsumptionPointsInfo } from '@app/stepper/stepper.interface';
import { ModalService } from 'paperflow-web-components';
import { ConfirmationModalComponent } from '@app/confirmation-modal/confirmation-modal.component';
import { map, tap } from 'rxjs/operators';
import * as copywrite from '@assets/global.json';
import { LoaderService } from '@app/shared/services/loader.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { InfoModalComponent } from '@app/info-modal/info-modal.component';
import { Utils } from '@app/shared/services/utils';
import { COMMODITIES } from '@app/core/dictionary/dictionary';

@Component({
  selector: 'om-consumption-points',
  templateUrl: './consumption-points.component.html',
  styleUrls: ['./consumption-points.component.scss'],
})
export class ConsumptionPointsComponent implements OnInit {
  @Input() data$: Observable<ConsumptionPoints>;
  public consumptionPointsData$: Observable<ConsumptionPointsInfo>;
  public loaderState$: Observable<{ loading: boolean; message?: string }>;
  public consumptionPoints: Array<ConsumptionPoint>;
  public addPointAction: () => any;
  public requestOfferAction: (data) => any;
  public editAction: (data) => any;
  public deleteAction: (data) => any;
  public texts = copywrite.consumptionPoints;
  private modal: any;
  public userType: string;
  public hasGasCP = false;
  public hasEnergyCP = false;
  public selectedOffer: string;
  public loaderMessage = '';

  constructor(
    private modalService: ModalService,
    private loader: LoaderService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.deletePoint = this.deletePoint.bind(this);
  }

  ngOnInit(): void {
    Utils.scrollToTop();
    this.loaderState$ = this.loader.loader$;

    this.consumptionPointsData$ = this.data$.pipe(
      map((data) => {
        if (data && data.actionsFn && data.actions && data.actions.length) {
          this.addPointAction = data.actionsFn.add_consumption_point;
          this.requestOfferAction = data.actionsFn.request_personalized_offer;
          this.editAction = data.actionsFn.edit_consumption_point;
          this.deleteAction = data.actionsFn.delete_consumption_point;
        }
        return data.consumptionPointsInformation;
      }),
      tap((data) => {
        this.userType = data.userType;
        this.selectedOffer = data.selectedOffer;
        this.loaderMessage = data.loaderMessage || this.loaderMessage;
        if (data && data.consumptionPoints && data.consumptionPoints.length) {
          this.consumptionPoints = data.consumptionPoints;
          this.handleTypeChanges();
          this.loader.setLoaderState({ loading: false });
        }
      }),
    );
  }

  handleEditAction(point): void {
    if (point && point.id && this.editAction) {
      this.loader.setLoaderState({ loading: true });
      this.editAction({ consumptionPointId: point.id }).subscribe();
      this.googleAnalyticsService.eventEmitter(
        'Edit consumption point',
        'custom_events',
        'consumption_points',
        'edit_consumption_point',
        1,
      );
    }
  }

  handleDeleteAction(point): void {
    if (point && point.id && this.deleteAction) {
      this.modal = this.modalService.show(
        ConfirmationModalComponent,
        {
          closeOnBackdropClick: false,
        },
        {
          payload: { consumptionPointId: point.id },
          action: this.deleteAction,
          callback: this.deletePoint,
          texts: copywrite.cpConfirmationModal,
        },
      );
    }
  }

  handleAddPoint(): void {
    this.loader.setLoaderState({ loading: true });
    this.addPointAction().subscribe();
    this.googleAnalyticsService.eventEmitter(
      'Add new consumption point',
      'custom_events',
      'consumption_points',
      'new_consumption_point',
      1,
    );
  }

  deletePoint({ consumptionPointId }): void {
    this.consumptionPoints = this.consumptionPoints.filter((item) => item.id !== consumptionPointId);
    this.handleTypeChanges();

    this.googleAnalyticsService.eventEmitter(
      'Delete consumption point',
      'custom_events',
      'consumption_points',
      'delete_consumption_point',
      1,
    );
  }

  handleRequest(data): void {
    this.loader.setLoaderState({ loading: true });

    const hasDuplicates = this.validateClcDuplicate() || this.validatePodDuplicate();

    if (hasDuplicates) {
      this.loader.setLoaderState({ loading: false });

      this.modal = this.modalService.show(InfoModalComponent, {
        closeOnBackdropClick: false,
      });
    } else {
      if (this.userType) {
        this.loader.setLoaderState({ loading: true, message: this.loaderMessage });
      }
      this.requestOfferAction(data).subscribe();
      this.googleAnalyticsService.eventEmitter('Get offer', 'custom_events', 'consumption_points', 'get_offer', 1);
    }
  }

  handleTypeChanges(): void {
    this.hasGasCP = !!this.consumptionPoints.filter((item) => !!item.gas).length;
    this.hasEnergyCP = !!this.consumptionPoints.filter((item) => !!item.energy).length;
  }

  validateClcDuplicate(): boolean {
    const seen = new Set();
    return this.consumptionPoints.some((currentObject) => {
      return currentObject.gas ? seen.size === seen.add((currentObject.gas as any).clcCode).size : false;
    });
  }

  validatePodDuplicate(): boolean {
    const seen = new Set();
    return this.consumptionPoints.some((currentObject) => {
      return currentObject.energy ? seen.size === seen.add((currentObject.energy as any).podCode).size : false;
    });
  }

  checkCanContinue(): boolean {
    return (
      (this.selectedOffer === COMMODITIES.GAS && this.hasGasCP) ||
      (this.selectedOffer === COMMODITIES.POWER && this.hasEnergyCP) ||
      (this.selectedOffer === COMMODITIES.ENERGY && this.hasGasCP && this.hasEnergyCP)
    );
  }
}
