<footer class="footer">
  <div class="footer-wrapper" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxFlexOrder.lt-md="2">
      <div class="copyright-claims">{{ texts.copyright }}</div>
      <div>
        <a id="logo">
          <img src="../../assets/omvp-logo.png" class="logo-icon" alt="OMVP logo" />
        </a>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign.lt-md="space-around center" class="links-wrapper mb-16" fxFlexOrder.lt-md="1">
      <a [href]="texts.utilioUrl" target="_blank" class="link">{{ texts.utilio }}</a>
      <a (click)="openPrivacy()" class="link">{{ texts.privacyPolicy }}</a>
      <a [href]="texts.faqUrl" target="_blank" class="link">{{ texts.faq }}</a>
    </div>
  </div>
</footer>

<om-privacy-modal [hidden]="!showPrivacy" (privacyClosed)="showPrivacy = false"></om-privacy-modal>
