import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'paperflow-web-components';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { tap, map } from 'rxjs/operators';
import { COMMON_DICTIONARY } from '@app/shared/constants/stepper.constants';
import { RecapModalInput } from '@app/stepper/interfaces';
import { LoaderService } from '@app/shared/services/loader.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { StorageMap } from '@ngx-pwa/local-storage';
import * as copywrite from '@assets/global.json';

@Component({
  selector: 'om-recap-modal',
  templateUrl: './recap-modal.component.html',
  styleUrls: ['./recap-modal.component.scss'],
})
export class RecapModalComponent implements OnInit {
  @Input() public data$: Observable<any>;
  public modalData$: Observable<RecapModalInput>;
  private goToAccountAction: () => any;
  public goToAccountActionObject = { label: '', name: '', endpoint: '' };
  public continueAction: () => any;
  private processUuid: string;
  public loaderState$: Observable<{ loading: boolean; message?: string }>;
  public texts = copywrite.recapModal;

  constructor(
    private modalService: ModalService,
    private router: Router,
    private loader: LoaderService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private oauthService: OAuthService,
    private route: ActivatedRoute,
    private storage: StorageMap,
  ) {}

  ngOnInit(): void {
    if (!(localStorage.getItem('redirected_after_login') === 'done')) {
      this.storage.set('modalOpened', true).subscribe();
    }

    this.route.queryParams.subscribe((params) => {
      if (params.processUuid) {
        this.processUuid = params.processUuid;
      }
    });

    this.modalData$ = this.data$.pipe(
      tap((data) => {
        if (data && data.actionsFn) {
          this.continueAction = data.actionsFn?.continue_to_stepper;
          this.goToAccountAction = data.actionsFn?.navigate_myaccount;
          this.goToAccountActionObject = data.actions.filter((action) => action.name === 'navigate_myaccount')[0];

          if (localStorage.getItem('redirected_after_login') === 'done' && this.continueAction) {
            this.continueAction().subscribe();
          }
        }
      }),
      map((data) => {
        // return null to show loader if coming back from login
        return localStorage.getItem('redirected_after_login') === 'done' ? null : data;
      }),
    );

    this.googleAnalyticsService.eventEmitter(
      'Recap modal',
      'in_app_page_view',
      'recap_screen',
      'modal_recap_screen',
      1,
    );

    this.loaderState$ = this.loader.loader$;
    this.loader.setLoaderState({ loading: false });
  }

  goToAction(): void {
    if (this.goToAccountActionObject.name === COMMON_DICTIONARY.GO_HOME_KEY) {
      this.goToHome();
    } else if (this.goToAccountActionObject.name === COMMON_DICTIONARY.GO_TO_COLLABORATION_KEY) {
      this.goToCollaboration();
      this.googleAnalyticsService.eventEmitter(
        'Redirect MyAccount',
        'custom_events',
        'recap_screen',
        'redirect_myaccount',
        1,
      );
    } else {
      this.goToAccountAction().subscribe();
      this.modalService.hide();
    }
  }

  login(): void {
    this.oauthService.initImplicitFlow(this.processUuid);
  }

  goToHome(): void {
    this.modalService.hide();
    this.router.navigateByUrl('/home');
  }

  goToCollaboration(): void {
    this.modalService.hide();
    window.open(this.goToAccountActionObject.endpoint, '_blank');
    this.router.navigateByUrl('/home');
  }

  goTo(url: string, service: string): void {
    window.open(url, '_blank');

    if (service) {
      this.googleAnalyticsService.eventEmitter(
        'Redirect to Store',
        'custom_events',
        'redirect_to_store',
        `redirect_${service}`,
        1,
      );
    }
  }
}
