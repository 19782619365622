import { Component } from '@angular/core';
import * as copywrite from '@assets/global.json';

@Component({
  selector: 'om-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public texts = copywrite.footer;
  public showPrivacy = false;

  openPrivacy(): void {
    this.showPrivacy = true;
  }
}
