import { Component, OnInit, Inject } from '@angular/core';
import { MODAL_DATA } from 'paperflow-web-components';
import { ModalService } from 'paperflow-web-components';

import * as copywrite from '../../assets/global.json';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';
import { ExportFileService } from '@app/shared/services/export-file.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'om-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA) public data: { url: string; title: string },
    private modalService: ModalService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private exportFileService: ExportFileService,
  ) {}

  public numberOfPages = 1;
  public currentPage = 1;
  public docName: string;
  public docSrc$: Observable<string>;
  public texts = copywrite.documentPreview;

  ngOnInit(): void {
    if (this.data) {
      this.docSrc$ = this.exportFileService.toBlob(this.data.url);
      this.docName = this.data.title;
    }
  }

  downloadPdf(url): void {
    this.exportFileService.downloadPdf(url, this.docName);
  }

  afterLoadComplete(pdf): void {
    this.numberOfPages = pdf.numPages;
  }

  closeModal(): void {
    this.modalService.hide();
  }
}
