<ppf-modal *ngIf="modalData$ | async as data; else loading" class="om-modal customer-modal ppf-modal-panel">
  <ppf-card class="pl-64 pr-64">
    <div fxLayout="row" fxLayoutAlign="end center" class="title">
      <button class="modal-close" (click)="goToHome()">
        <ppf-icon name="close"></ppf-icon>
      </button>
    </div>
    <div *ngIf="data.image" fxLayout="row" fxLayoutAlign="center center">
      <img [src]="data.image" alt="C2 image" class="modal-header-image" />
    </div>
    <div class="card-content">
      <h2 class="modal-title mt-24 text-center">{{ data.title | flxLocalize }}</h2>
      <p class="text-center">{{ data.subtitle | flxLocalize }}</p>
      <div
        *ngIf="data.benefits && data.benefits.length"
        class="benefits-box info-container mt-24"
        fxLayout="column"
        fxLayoutGap="16px"
      >
        <div *ngFor="let benefit of data.benefits" class="benefit" fxLayout="row">
          <img [src]="data.image" alt="C2 image" class="benefit-image" />
          <div class="benefit-box" fxLayout="column" fxLayoutAlign="center start">
            <div class="info-title">{{ benefit.title | flxLocalize }}</div>
            <div class="info-subtitle">{{ benefit.subtitle | flxLocalize}}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="actionsMeta && actionsMeta.length"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="btn-wrapper mt-24"
    >
      <ppf-button
        *ngFor="let action of actionsMeta"
        [title]="action.label || texts.continueActionLabel | flxLocalize"
        [type]="action.main ? 'fill' : 'border'"
        omPreventDoubleClick
        (throttledClick)="doAction(action.name)"
      ></ppf-button>
    </div>
  </ppf-card>
</ppf-modal>

<ng-template #loading class="loader">
  <ppf-spinner
    backgroundColor="#ffffff"
    borderColor="transparent"
    visualType="custom"
    size="custom"
    [radius]="90"
  ></ppf-spinner>
</ng-template>
