import { Inject, Injectable } from '@angular/core';
import { AuthConfig, NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { JWT_TOKEN_KEY } from '../dictionary/dictionary';
import { filter, tap } from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { RouterEvent } from '@angular/router';

@Injectable()
export class AuthService {
  private _decodedAccessToken: any;
  private _decodedIDToken: any;

  get decodedAccessToken(): string {
    return this._decodedAccessToken;
  }

  get decodedIDToken(): string {
    return this._decodedIDToken;
  }

  constructor(
    @Inject('BASE_API_URL') private baseApiUrl: string,
    private oauthService: OAuthService,
    private authConfig: AuthConfig,
    private http: HttpClient,
  ) {}

  async initAuth(): Promise<any> {
    return new Promise((resolveFn) => {
      // setup oauthService
      this.oauthService.configure(this.authConfig);
      this.oauthService.setStorage(localStorage);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();

      // subscribe to token events
      this.oauthService.events
        .pipe(
          filter((e: any) => {
            return e.type === 'token_received';
          }),
        )
        .subscribe(() => {
          this.handleNewToken();
        });

      this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
        this.oauthService.setupAutomaticSilentRefresh();
        resolveFn();
      });
    });
  }

  private handleNewToken(): void {
    this._decodedAccessToken = this.oauthService.getAccessToken();
    this._decodedIDToken = this.oauthService.getIdToken();
  }

  getUserAttributes(): any {
    return (jwtDecode(this.oauthService.getAccessToken()) as any).attributes;
  }

  handleAuth(): Promise<any> {
    return this.http
      .get(`${this.baseApiUrl}/keycloak/token`, { responseType: 'text' })
      .pipe(
        tap((data: string) => {
          localStorage.removeItem(JWT_TOKEN_KEY);
          localStorage.setItem(JWT_TOKEN_KEY, data);
        }),
      )
      .toPromise();
  }
}
