export enum HttpErrorStatus {
  UNKNOWN_ERROR = 0,
  UNAUTHORIZED = 401,
}

export const JWT_TOKEN_KEY = 'access_token';

export const COMMODITIES = {
  GAS: 'GAS',
  POWER: 'POWER',
  ENERGY: 'ENERGY',
};

export const DOC_TYPES = {
  GAS: 'gas',
  POWER: 'power',
};
