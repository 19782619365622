<div class="upload-documents" *ngIf="uploadDocumentsData$ | async as data; else loading">
  <h3 class="headline mb-24">{{ data.uploadDocumentsData.title | flxLocalize }}</h3>
  <ppf-card fxLayout="row wrap" fxLayout.lt-md="column" class="pt-16 pb-16 pr-8 pl-8">
    <div *ngFor="let field of data.uploadDocumentsData.fields" fxFlex="50" class="pl-8 pr-8 mb-16">
      <div
        fxLayout="row"
        fxLayoutAlign="start start"
        class="upload-documents-item"
        [ngClass]="{ uploaded: field.document }"
      >
        <div class="save">
          <img [src]="field.image" alt="Upload image" class="upload-documents-item-image" />
        </div>
        <div class="upload-item-text-container">
          <div class="text-bold mb-8">{{ field.title }}</div>
          <div *ngIf="!field.document">
            <div>
              <label class="link pointer">
                <input
                  type="file"
                  accept="image/png,image/jpeg,application/pdf"
                  (change)="upload($event, field.id)"
                />{{ field.linkText }}
              </label>
              &nbsp;
              <span>{{ field.text }}</span>
            </div>
            <div class="upload-documents-item-info">{{ field.info }}</div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="field.document">
            <div>
              <span>{{ field.document.title }}</span>
              <span class="upload-documents-item-info ml-12">{{ field.document.size }}</span>
            </div>
            <button
              class="delete-document"
              omPreventDoubleClick
              (throttledClick)="deleteUploadedDocument(field.document)"
            >
              <ppf-icon name="close"></ppf-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.lt-md="center center" class="upload-btn">
      <ppf-button
        [title]="'Continuă'"
        class="save-config-btn mt-32"
        type="fill"
        [disabled]="isDisabled(data)"
        omPreventDoubleClick
        (throttledClick)="doAction()"
      ></ppf-button>
    </div>
  </ppf-card>
</div>
<ng-template #loading class="loader">
  <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
</ng-template>
