<div class="confirmation-modal-wrapper">
  <ppf-card class="confirmation-modal">
    <div class="card-header text-center headline mb-24">{{ texts.title }}</div>
    <div class="card-content mb-24">
      <div class="text-center question">{{ texts.deleteQuestion }}</div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="card-footer">
      <ppf-button [title]="texts.yesAnswer" class="action-btn" type="fill" (onClick)="handleAction()"></ppf-button>
      <ppf-button [title]="texts.noAnswer" class="action-btn" type="border" (onClick)="handleCancel()"></ppf-button>
    </div>
  </ppf-card>
</div>
