<div *ngIf="bindingOfferData$ | async as data; else loading" class="binding-offer-page" fxLayout="column">
  <div *ngIf="data[key]">
    <div fxLayout="row" class="header-wrapper" fxLayoutAlign="space-between center">
      <div class="headline page-title">
        {{ data[key].title }}
        <span
          [ngClass]="{
            green: data[key].bindingOfferType === commodities.GAS,
            blue: data[key].bindingOfferType === commodities.POWER
          }"
          >{{ data[key].subtitle }}</span
        >
      </div>
      <div *ngIf="data[key].offerUrl" class="download-wrapper" fxLayout="row">
        <ppf-icon name="download" class="download-icon pr-8"></ppf-icon>
        <a
          (click)="
            downloadPdf(
              data[key].offerUrl,
              texts.docTitle + (data[key].bindingOfferType === commodities.GAS ? ' Gas' : ' Power')
            )
          "
          >{{ texts.downloadAction }}</a
        >
      </div>
    </div>

    <div fxLayout="row">
      <div class="options-section config-layout-wrapper">
        <div fxLayout="row" fxLayout.lt-md="column">
          <div>
            <ppf-card *ngFor="let point of consumptionPoints" class="page-card mb-16">
              <div class="card-image" fxLayout="column">
                <img [src]="point.image" class="image" alt="general terms and conditions" />
              </div>
              <div class="consumption-info">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <h2 class="consumption-title headline">{{ point.title }}</h2>
                  <div *ngIf="point.address" class="address-wrapper" fxLayout="row">
                    <ppf-icon class="map-icon" name="small-pin"></ppf-icon>
                    <span class="address">{{ point.address }}</span>
                  </div>
                </div>
                <div *ngIf="point?.fields?.length" class="info-wrapper">
                  <div *ngFor="let field of point.fields" fxLayout="column" class="info-field">
                    <div class="key">{{ field.key }}</div>
                    <div class="value">
                      <span
                        [ngClass]="{ 'highlighted': field.main, 'open-modal': field.key === tariffsTexts.title }"
                        (click)="openModal(point, field)"
                      >
                        {{ field.value }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ppf-card>

            <ppf-card *ngIf="documentsData" class="page-card mb-16">
              <div class="card-image" fxLayout="column">
                <img [src]="documentsData.image" class="image" alt="general terms and conditions" />
              </div>
              <div class="card-content">
                <div class="title pb-8 pt-8 headline">{{ documentsData.title }}</div>
                <div class="subtitle">{{ documentsData.subtitle }}</div>

                <div class="documents-wrapper">
                  <div *ngFor="let document of documentsData.list" class="document" fxLayout="row">
                    <ppf-icon name="small-doc" class="document-icon mt-4 pr-8"></ppf-icon>
                    <a [href]="document.url" target="_blank" download>{{ document.label }}</a>
                  </div>
                </div>
              </div>
            </ppf-card>

            <ppf-card
              *ngIf="includedServices && includedServices.length && data[key].bindingOfferType === commodities.GAS"
              class="page-card mb-16"
            >
              <div *ngFor="let service of includedServices" fxLayout="row">
                <div *ngIf="service.image" class="card-image">
                  <img [src]="service.image" class="image" alt="general terms and conditions" />
                </div>
                <div class="card-content">
                  <div class="title pb-16 pt-8 headline">{{ service.title }}</div>
                  <div class="summary-wrapper">
                    <div *ngFor="let item of service.services" class="summary-item">
                      <div class="summary-info" fxLayout="column">
                        <ul class="headline text-bold summary-value">
                          <li>
                            <div class="headline text-bold">{{ item.title }}</div>
                          </li>
                        </ul>
                        <span class="summary-message">{{ item.message }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ppf-card>
          </div>

          <div class="product-section mb-16">
            <ppf-card *ngIf="configuredOffer" class="product-card" fxLayout="column" fxLayoutAlign="start center">
              <img [src]="configuredOffer.image" alt="Product image" class="image mb-12" />
              <div
                class="product-title headline"
                [ngClass]="{
                  green: data[key].bindingOfferType === commodities.GAS,
                  blue: data[key].bindingOfferType === commodities.POWER
                }"
              >
                {{ configuredOffer.title }}
              </div>

              <div class="product-fields">
                <div *ngFor="let field of configuredOffer.fields" class="field-container pb-16 pt-16">
                  <div *ngIf="field?.values?.length" fxLayout="row" fxLayoutAlign="center center" class="pb-4">
                    <span class="field-key"> {{ field.title }}</span>
                    <ppf-icon
                      *ngIf="field.tooltipText"
                      name="question"
                      class="ml-4 field-icon"
                      [ppfTooltip]="field.tooltipText"
                    ></ppf-icon>
                  </div>
                  <div *ngFor="let value of field.values" fxLayoutAlign="center center">
                    <div [ngClass]="field.valueClass" class="field-value" [innerHTML]="value"></div>
                  </div>
                </div>
              </div>
              <div *ngIf="getActionKey(data)" fxLayout="row" fxLayoutAlign="center center" class="offer-action">
                <ppf-button
                  [title]="texts.actionLabel"
                  class="save-config-btn mt-32"
                  type="fill"
                  omPreventDoubleClick
                  (throttledClick)="doAction()"
                ></ppf-button>
              </div>
            </ppf-card>
          </div>
        </div>

        <div
          *ngIf="data[key].bottomInfo"
          fxLayout="row"
          fxLayoutAlign="start start"
          class="info-wrapper info-container pl-32 mt-32"
        >
          <ppf-icon name="info" class="info-icon mr-24"></ppf-icon>
          <div class="info-wrapper">
            <p *ngFor="let info of data[key].bottomInfo">
              {{ info }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading class="loader">
  <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
</ng-template>
