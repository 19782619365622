import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { ContractualConditions } from '@app/stepper/interfaces';
import { Utils } from '@app/shared/services/utils';
import { CONTRACTUAL_CONDITIONS } from '@app/shared/constants/stepper.constants';
import { LoaderService } from '@app/shared/services/loader.service';
import { GoogleAnalyticsService } from '@app/shared/services/google-analytics.service';

@Component({
  selector: 'om-contractual-conditions',
  templateUrl: './contractual-conditions.component.html',
  styleUrls: ['./contractual-conditions.component.scss'],
})
export class ContractualConditionsComponent implements OnInit, OnDestroy {
  @Input() data$: Observable<ContractualConditions>;
  contractualConditionsData$: Observable<ContractualConditions>;
  public contractTermsForm = new FormGroup({});
  public loaderState$: Observable<{ loading: boolean }>;

  private submitConsentAction: (form) => any;

  constructor(private loader: LoaderService, private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    Utils.scrollToTop();
    this.loaderState$ = this.loader.loader$;

    this.contractualConditionsData$ = this.data$?.pipe(tap((data) => this.createForm(data)));
    this.googleAnalyticsService.eventEmitter(
      'Contractual conditions page',
      'in_app_page_view',
      'contractual_conditions',
      'page_contractual_conditions',
      1,
    );
  }

  createForm(data): void {
    if (data?.actionsFn && data.actionsFn.continue_step) {
      this.submitConsentAction = data.actionsFn.continue_step;
    }

    data?.contractualConditionsInformation?.termsList?.forEach((option) => {
      if (
        option.key === CONTRACTUAL_CONDITIONS.TOC_AGREEMENT_KEY ||
        option.key === CONTRACTUAL_CONDITIONS.GDPR_AGREEMENT_KEY
      ) {
        return this.contractTermsForm.addControl(option.key, new FormControl(false, this.validate(option.key)));
      }

      return this.contractTermsForm.addControl(option.key, new FormControl(false));
    });

    data?.contractualConditionsInformation?.gasDropdownList?.forEach((item) => {
      return this.contractTermsForm.addControl(item.key, new FormControl(item.options[0]));
    });

    data?.contractualConditionsInformation?.powerDropdownList?.forEach((item) => {
      return this.contractTermsForm.addControl(item.key, new FormControl(item.options[0]));
    });
  }

  saveForm(): void {
    this.loader.setLoaderState({ loading: true });

    this.submitConsentAction({
      application: {
        contact: this.contractTermsForm.value,
      },
    }).subscribe();
  }

  getActionKey(data): any {
    return Utils.getActionKey(data);
  }

  getActionLabel(data): string {
    return Utils.getActionLabel(data);
  }

  validate(error: string): any {
    return (control: FormControl) => {
      return !control.value ? { [error]: true } : null;
    };
  }

  onSelectChanged(event): void {
    let action = '';

    switch (event.value) {
      case 'by_email':
        action = 'electronic_invoice';
        break;
      case 'by_post':
        action = 'physical_invoice';
        break;
    }

    if (action) {
      this.googleAnalyticsService.eventEmitter(
        'Contractual conditions',
        'custom_events',
        'contractual_conditions',
        action,
        1,
      );
    }
  }

  ngOnDestroy(): void {
    Utils.scrollToTop();
  }
}
