import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorStatus } from './dictionary/dictionary';
import { AuthService } from './auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case HttpErrorStatus.UNKNOWN_ERROR:
            console.log('UNKNOWN ERROR OCCURED', error);
            break;
          case HttpErrorStatus.UNAUTHORIZED:
            this.authService.handleAuth();
            break;
        }

        return throwError(error);
      }),
    );
  }
}
