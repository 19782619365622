import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from '@app/shared/services/loader.service';

@Component({
  selector: 'om-loader-handler',
  templateUrl: './loader-handler.component.html',
  styleUrls: ['./loader-handler.component.scss'],
})
export class LoaderHandlerComponent implements OnInit {
  public loaderState$: Observable<{ loading: boolean }>;

  constructor(private loader: LoaderService) {}

  ngOnInit(): void {
    this.loaderState$ = this.loader.loader$;
    this.loader.setLoaderState({ loading: false });
  }
}
