<div *ngIf="documentsSigningData$ | async as data; else loading" class="documents-signing-page">
  <div *ngIf="data.documentsSigningInformation">
    <div class="headline page-title">{{ data.documentsSigningInformation.title | flxLocalize }}</div>

    <ppf-card class="page-card" fxLayout="column" [formGroup]="documentsSigningForm">
      <div
        *ngIf="documentsData && documentsData.list && documentsData.list.length"
        fxLayout="column"
        class="card-content"
      >
        <div fxLayout="row">
          <div class="card-image">
            <img [src]="documentsData.image" class="image" alt="documents signing" />
          </div>
          <div>
            <div class="title pb-8 pt-8 headline">{{ documentsData.title | flxLocalize }}</div>
            <div class="subtitle">{{ documentsData.subtitle | flxLocalize }}</div>
          </div>
        </div>

        <div class="documents-wrapper" fxLayout="row wrap" fxLayoutGap="16px" fxLayoutGap.lt-md="0">
          <div fxLayout="column" [fxFlex]="documentsData.powerList.length ? 48 : 100" fxFlex.lt-md="100">
            <ppf-card
              *ngFor="let document of documentsData.gasList"
              class="document-item"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutAlign.lt-sm="start start"
              fxFlex="48"
              fxFlex.lt-md="100"
            >
              <div fxLayout="column">
                <ppf-icon name="small-sign"></ppf-icon>
              </div>

              <div
                class="document-info pl-16"
                fxLayout="row"
                fxLayout.lt-sm="column"
                fxLayoutAlign.lt-sm="center start"
                fxLayoutAlign="space-between center"
                fxLayoutGap.lt-sm="16px"
              >
                <div class="headline text-bold">{{ document.label }}</div>
                <ppf-button
                  class="ml-16"
                  [title]="texts.signBtnLabel"
                  type="border"
                  (click)="viewDocument(document.url, document.label)"
                ></ppf-button>
              </div>
            </ppf-card>
          </div>
          <div fxLayout="column" [fxFlex]="documentsData.gasList.length ? 48 : 100" fxFlex.lt-md="100">
            <div class="divider mt-48"></div>
            <ppf-card
              *ngFor="let document of documentsData.powerList"
              class="document-item"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutAlign.lt-sm="start start"
              fxFlex="48"
              fxFlex.lt-md="100"
            >
              <div fxLayout="column">
                <ppf-icon name="small-sign"></ppf-icon>
              </div>

              <div
                class="document-info pl-16"
                fxLayout="row"
                fxLayout.lt-sm="column"
                fxLayoutAlign.lt-sm="center start"
                fxLayoutAlign="space-between center"
                fxLayoutGap.lt-sm="16px"
              >
                <div class="headline text-bold">{{ document.label }}</div>
                <ppf-button
                  class="ml-16"
                  [title]="texts.signBtnLabel"
                  type="border"
                  (click)="viewDocument(document.url, document.label)"
                ></ppf-button>
              </div>
            </ppf-card>
          </div>
        </div>
      </div>

      <div
        *ngIf="signatureData"
        class="signature-section card-content"
        fxLayout="column"
        fxLayoutAlign.lt-md="center center"
      >
        <div fxLayout="row" fxFlexFill>
          <div class="card-image">
            <img [src]="signatureData.image" class="image" alt="documents signing" />
          </div>
          <div>
            <div class="signature-wrapper" fxLayout="column">
              <div class="title pb-8 pt-8 headline">{{ signatureData.title | flxLocalize }}</div>
              <div class="subtitle" fxLayout="row" fxLayoutAlign="space-between end">
                <span class="mr-8">{{ signatureData.subtitle | flxLocalize }}</span>
                <a class="link pointer" (click)="resetSignature()">{{ texts.reset }}</a>
              </div>
            </div>
          </div>
        </div>

        <signature-pad
          fxFlex="1 1 auto"
          class="signature-component"
          [options]="signaturePadOptions"
          (onEndEvent)="drawComplete()"
        >
          <input formControlName="signature" />
        </signature-pad>
      </div>
      <div class="card-footer mt-32" fxLayoutAlign.lt-md="center" fxLayoutAlign="end">
        <ppf-button
          *ngIf="getActionKey(data)"
          [title]="'Continuă'"
          [disabled]="documentsSigningForm.invalid"
          type="fill"
          omPreventDoubleClick
          (throttledClick)="saveForm()"
        ></ppf-button>
      </div>
    </ppf-card>

    <div
      *ngIf="data.documentsSigningInformation.bottomInfo"
      fxLayout="row"
      fxLayoutAlign="start start"
      class="info-wrapper info-container pl-32 mt-32"
    >
      <ppf-icon name="info" class="info-icon mr-24"></ppf-icon>
      <div class="info-wrapper">
        <p *ngFor="let info of data.documentsSigningInformation.bottomInfo">
          {{ info | flxLocalize }}
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #loading class="loader">
  <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
</ng-template>

<ng-container *ngIf="loaderState$ | async as loaderState">
  <div *ppfShowModal="loaderState.loading">
    <ppf-spinner backgroundColor="#ffffff" borderColor="transparent" visualType="custom" size="custom" [radius]="90">
      <div *ngIf="loaderState.message" class="spinner-message">
        {{ loaderState.message }}
      </div>
    </ppf-spinner>
  </div>
</ng-container>
