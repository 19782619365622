import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { OffersComponent } from '@app/stepper/offers/offers.component';
import { ConfigureOfferComponent } from './configure-offer/configure-offer.component';
import { EmailValidationModalComponent } from '@app/stepper/confirmation-modal/email-validation-modal.component';
import { ConsumptionPointsComponent } from '@app/stepper/consumption-points/consumption-points.component';
import { BindingOfferComponent } from './binding-offer/binding-offer.component';
import { ContractualConditionsComponent } from './contractual-conditions/contractual-conditions.component';
import { DocumentsSigningComponent } from './documents-signing/documents-signing.component';
import { CustomerModalComponent } from '@app/stepper/customer-modal/customer-modal.component';
import { RecapModalComponent } from './recap-modal/recap-modal.component';
import { UploadDocumentsComponent } from '@app/stepper/upload-documents/upload-documents.component';
import { TariffsModalComponent } from '@app/stepper/tarrifs-modal/tariffs-modal.component';
import { PowerBindingOfferComponent } from '@app/stepper/binding-offer/power-binding-offer.component';
import { EnergyBindingOfferComponent } from '@app/stepper/energy-binding-offer/energy-binding-offer.component';
import { MasterBindingOfferComponent } from './master-binding-offer/master-binding-offer.component';
import { ErrorHandlingComponent } from '@app/stepper/error-handling/error-handling.component';

@NgModule({
  declarations: [
    OffersComponent,
    ConfigureOfferComponent,
    EmailValidationModalComponent,
    ConsumptionPointsComponent,
    BindingOfferComponent,
    PowerBindingOfferComponent,
    ContractualConditionsComponent,
    DocumentsSigningComponent,
    CustomerModalComponent,
    RecapModalComponent,
    UploadDocumentsComponent,
    TariffsModalComponent,
    EnergyBindingOfferComponent,
    MasterBindingOfferComponent,
    ErrorHandlingComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class StepperModule {}
