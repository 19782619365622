<div class="master-binding-offer">
  <div *ngIf="bindingOfferData$ | async; else loading">
    <om-binding-offer *ngIf="selectedOffer === commodities.GAS" [data$]="bindingOfferData$"></om-binding-offer>
    <om-power-binding-offer
      *ngIf="selectedOffer === commodities.POWER"
      [data$]="bindingOfferData$"
    ></om-power-binding-offer>
    <om-energy-binding-offer
      *ngIf="selectedOffer === commodities.ENERGY"
      [data$]="bindingOfferData$"
    ></om-energy-binding-offer>
  </div>

  <ng-template #loading class="loader">
    <ppf-spinner visualType="custom" size="custom" [radius]="90"></ppf-spinner>
  </ng-template>
</div>
