export const arrowLeft = {
  name: 'arrowLeft',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="6.401" height="10.715" viewBox="0 0 6.401 10.715">
    <path id="arrow" fill="currentColor" d="M5.658 9.8L.3 4.444 1.274 3.4l4.384 4.384L10.041 3.4l.974 1.044z" transform="rotate(90 5.05 4.75)"/>
</svg>
`,
};

export const arrowRight = {
  name: 'arrowRight',
  data: `<svg xmlns="http://www.w3.org/2000/svg" style="transform: scale(-1,1)" width="6.401" height="10.715" viewBox="0 0 6.401 10.715">
    <path id="arrow" fill="currentColor" d="M5.658 9.8L.3 4.444 1.274 3.4l4.384 4.384L10.041 3.4l.974 1.044z" transform="rotate(90 5.05 4.75)"/>
</svg>`,
};

export const sign = {
  name: 'sign',
  data: `<svg xmlns="http://www.w3.org/2000/svg" fill:"#444444" width="23" height="23" viewBox="0 0 23 23">
    <g data-name="sign (2)" opacity="0.1">
        <path id="Path_15130" d="M22.281 30H.719a.719.719 0 0 0 0 1.438h21.562a.719.719 0 0 0 0-1.437z"  data-name="Path 15130" transform="translate(0 -8.438)"/>
        <path id="Path_15131" d="M2.6 20.423l1.5-1.5a3.251 3.251 0 0 0 4.042-.447L21.053 5.565a3.26 3.26 0 1 0-4.61-4.61L3.532 13.867a3.262 3.262 0 0 0-.448 4.042l-1.5 1.5z" data-name="Path 15131" transform="translate(-.446)"/>
        <path id="Path_15132" d="M23.6 18.369l5.329-5.329-2.454-2.454-1.014 1.014 1.439 1.44-4.312 4.312z"  data-name="Path 15132" transform="translate(-6.352 -2.977)"/>
    </g>
</svg>
`,
};

export const edit = {
  name: 'edit',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">\n' +
    '    <g>\n' +
    '        <path fill="#f4f4f4" d="M0 0H24V24H0z" opacity="0.3" transform="translate(-1167 -5618) translate(1167 5618)"/>\n' +
    '        <path fill="#5a77b3" d="M-1959 16a.945.945 0 0 1-1-1v-3a.9.9 0 0 1 .3-.7l7.8-7.8 4.4 4.4-7.8 7.8a.9.9 0 0 1-.7.3zm8.5-13.9l1.8-1.8a.967.967 0 0 1 1.4 0l3 3a.967.967 0 0 1 0 1.4l-1.8 1.8z" transform="translate(-1167 -5618) translate(3131 5622)"/>\n' +
    '    </g>\n' +
    '</svg>\n`,
};

export const remove = {
  name: 'remove',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g>
        <path fill="#f4f4f4" d="M0 0H24V24H0z" opacity="0.3" transform="translate(-1146 -5384) translate(1146 5384)"/>
        <path fill="#ff7676" d="M11.918 1.33a1.125 1.125 0 0 0-1.59 0l-3.7 3.7-3.7-3.7a1.125 1.125 0 1 0-1.599 1.59l3.7 3.7-3.7 3.7a1.125 1.125 0 1 0 1.591 1.599l3.7-3.7 3.7 3.7a1.125 1.125 0 1 0 1.591-1.591l-3.7-3.7 3.7-3.7a1.125 1.125 0 0 0 .007-1.598z" transform="translate(-1146 -5384) translate(1151.376 5389.376)"/>
    </g>
</svg>`,
};

export const question = {
  name: 'question',
  data: `<svg xmlns="http://www.w3.org/2000/svg" id="c-info" viewBox="0 0 16 16">
    <g id="bulb-62" transform="translate(4.028 2.704)">
        <path id="Path_14597" fill="#fff" d="M5.972 0A3.984 3.984 0 0 0 2 3.972a4.033 4.033 0 0 0 1.986 3.442V9.93a.625.625 0 0 0 .662.662H7.3a.625.625 0 0 0 .662-.662V7.414a4.033 4.033 0 0 0 1.982-3.442A3.984 3.984 0 0 0 5.972 0zM5.31 9.268v-.662h1.324v.662zm1.721-2.847a.579.579 0 0 0-.4.6v.927H5.31v-.931a.579.579 0 0 0-.4-.6 2.648 2.648 0 1 1 2.118 0z" data-name="Path 14597" transform="translate(-2)"/>
    </g>
    <g id="c-question_4_" data-name="c-question (4)">
        <path id="Path_15219" d="M8 0a8 8 0 1 0 8 8 8.024 8.024 0 0 0-8-8zm0 13a1 1 0 0 1 0-2 1 1 0 0 1 0 2zm1.5-4.6c-.5.3-.5.4-.5.6v1H7V9a2.614 2.614 0 0 1 1.4-2.3c.5-.3.6-.4.6-.7a.945.945 0 0 0-1-1 1.05 1.05 0 0 0-.9.5l-.5.9-1.7-1 .5-.9A2.984 2.984 0 0 1 8 3a2.946 2.946 0 0 1 3 3 2.722 2.722 0 0 1-1.5 2.4z" data-name="Path 15219"/>
    </g>
</svg>
`,
};

export const grayPin = {
  name: 'gray-pin',
  data: `<svg xmlns="http://www.w3.org/2000/svg" id="location-map-pin-disabled" width="199" height="199" viewBox="0 0 199 199">
    <defs>
        <style>
            .cls-4{fill:#bfc0c1}.cls-5{fill:#fff}
        </style>
    </defs>
    <g id="Layer_1">
        <g id="location-map-pin-disabled-2">
            <rect id="Rectangle_2622" width="199" height="199" fill="#f2f2f2" rx="28"/>
            <path id="Path_17358" fill="#e2e2e2" d="M155 130.533L139.07 84.39h-92.5l-15.93 46.143z" transform="translate(7.469 20.57)"/>
            <path id="Path_17359" fill="#d3d4d5" d="M86.327 35.08A29.017 29.017 0 0 0 57.31 64.1c0 20.659 29.017 55.384 29.017 55.384s29.054-34.75 29.054-55.384a29 29 0 0 0-29.054-29.02zm0 42.2a13.178 13.178 0 1 0 0-.012z" transform="translate(13.969 8.551)"/>
            <path id="Path_17360" d="M90.567 39.32A29.017 29.017 0 0 0 61.55 68.337c0 15.4 16.094 38.556 24.315 49.4 3.893-4.776 28.482-35.658 28.482-54.675a28.917 28.917 0 0 0-6.094-17.686 28.867 28.867 0 0 0-17.686-6.056zm-5.274 36.915a13.184 13.184 0 1 1 13.184-13.184 13.184 13.184 0 0 1-13.184 13.184z" class="cls-4" transform="translate(15.003 9.584)"/>
            <path id="Path_17361" d="M86.731 122.129a2.226 2.226 0 0 1-1.716-.808C83.771 119.84 55.65 84.928 55.65 64.394a31.094 31.094 0 1 1 62.187 0c0 21.144-28.183 55.484-29.39 56.939a2.2 2.2 0 0 1-1.716.796zm0-84.388a26.678 26.678 0 0 0-26.653 26.653c0 16.169 20.522 44 26.666 51.939 6.157-7.861 26.629-35.285 26.629-51.939A26.678 26.678 0 0 0 86.731 37.74z" class="cls-4" transform="translate(13.565 8.117)"/>
            <path id="Path_17362" d="M48.1 104.423h18.508c4.092 0 4.092-6.343 0-6.343H48.1c-4.09 0-4.09 6.343 0 6.343z" class="cls-5" transform="translate(10.977 23.907)"/>
            <path id="Path_17363" d="M67.4 115.3h34.415c4.092 0 4.092-6.343 0-6.343H67.4c-4.09.003-4.09 6.343 0 6.343z" class="cls-5" transform="translate(15.681 26.559)"/>
            <path id="Path_17364" d="M96.921 104.423h17.6c4.092 0 4.092-6.343 0-6.343h-17.6c-4.08 0-4.092 6.343 0 6.343z" class="cls-5" transform="translate(22.877 23.907)"/>
        </g>
    </g>
</svg>`,
};

export const smallDoc = {
  name: 'small-doc',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
    <path fill="#4f6f9b" d="M14 0H2a.945.945 0 0 0-1 1v14a.945.945 0 0 0 1 1h12a.945.945 0 0 0 1-1V1a.945.945 0 0 0-1-1zM8 13H4v-2h4zm4-4H4V7h8zm0-4H4V3h8z" transform="translate(-1)"/>
  </svg>`,
};

export const smallSign = {
  name: 'small-sign',
  data: `<svg xmlns="http://www.w3.org/2000/svg" id="document-sign" width="199" height="199" viewBox="0 0 199 199">
    <defs>
        <style>
            .cls-2{fill:#fff}.cls-3{fill:#d8d7d6}.cls-4{fill:#f7d35e}
        </style>
    </defs>
    <g id="Layer_1">
        <g id="document-sign-2">
            <rect id="Rectangle_2558" width="199" height="199" fill="#f2f2f2" rx="28"/>
            <path id="Path_17185" d="M43.33 151.891h91.217V56.545L109.672 31.67H43.33z" class="cls-2" transform="translate(10.562 7.72)"/>
            <path id="Path_17186" d="M134.951 154.375H43.735a2.065 2.065 0 0 1-2.065-2.075V32.077A2.065 2.065 0 0 1 43.735 30h66.342a2.077 2.077 0 0 1 0 4.142H45.812v116.091h87.063V56.952a2.077 2.077 0 1 1 4.142 0V152.3a2.065 2.065 0 0 1-2.065 2.077z" class="cls-2" transform="translate(10.157 7.312)"/>
            <path id="Path_17187" d="M100.725 56.545a4.067 4.067 0 0 1-4.055-4.154V31.67l24.776 24.875z" class="cls-3" transform="translate(23.563 7.72)"/>
            <path id="Path_17188" d="M121.94 59.016H97.065A2.065 2.065 0 0 1 95 56.952V32.077a2.077 2.077 0 0 1 3.545-1.468l24.477 24.564a2.077 2.077 0 0 1-1.082 3.843zm-22.8-4.142h17.723L99.142 37.089z" class="cls-3" transform="translate(23.156 7.313)"/>
            <rect id="Rectangle_2559" width="44.029" height="7.425" class="cls-4" rx="2.98" transform="translate(62.362 44.402)"/>
            <rect id="Rectangle_2560" width="23.868" height="7.425" class="cls-4" rx="2.98" transform="translate(62.362 56.839)"/>
            <path id="Path_17189" d="M100.866 95.888a1.5 1.5 0 0 0 1.953-.8L113.7 69.047a5.958 5.958 0 0 0-3.184-7.786l-5.435-2.239a1.493 1.493 0 0 0-1.953.8 1.468 1.468 0 0 0 .8 1.94L109.411 64a2.96 2.96 0 0 1 1.58 3.893l-10.97 26.044a1.5 1.5 0 0 0 .846 1.953z" class="cls-4" transform="translate(24.354 14.358)"/>
            <path id="Path_17190" d="M83.257 109.108a4.49 4.49 0 0 0 2.388 5.846 4.465 4.465 0 0 0 5.833-2.4l19.552-46.6a4.465 4.465 0 0 0-2.388-5.833 4.465 4.465 0 0 0-5.833 2.425z" class="cls-4" transform="translate(20.211 14.571)"/>
            <path id="Path_17191" d="M105.083 64.154a4.44 4.44 0 0 1 3.545-2.674 4.428 4.428 0 0 1 .572 4.39l-19.539 46.6a4.477 4.477 0 0 1-3.482 2.686 4.465 4.465 0 0 1-.572-4.4z" class="cls-4" transform="translate(20.784 14.986)"/>
            <path id="Path_17192" d="M82.2 108.886l19.552-46.628a5.945 5.945 0 1 1 10.957 4.6l-19.552 46.6a5.945 5.945 0 1 1-10.957-4.572zM104.48 63.4L84.941 110a2.974 2.974 0 1 0 5.485 2.3l19.552-46.6a2.98 2.98 0 1 0-5.5-2.3z" class="cls-4" transform="translate(19.895 14.246)"/>
            <path id="Path_17193" d="M94.63 99.588l-6.9 16.455c-1.754 4.191-4.975 10.821-8.706 9.241s-1.244-8.582.485-12.7l6.9-16.455z" class="cls-4" transform="translate(18.788 23.432)"/>
            <path id="Path_17194" d="M81.8 124.555a36.915 36.915 0 0 0 2.96-5.846c1.754-4.2 8.047-19.191 8.047-19.191L88.7 97.79s-5.186 12.338-6.9 16.455c-1.244 2.985-2.885 7.463-2.251 10.311z" class="cls-4" transform="translate(19.355 23.836)"/>
            <path id="Path_17195" d="M78.435 112.307l6.89-16.455a1.493 1.493 0 0 1 1.9-.833l8.221 3.445a1.493 1.493 0 0 1 .808 1.94l-6.866 16.5c-1.405 3.371-5.162 12.338-10.646 10.037s-1.725-11.264-.307-14.634zm9.065-13.93l-6.331 15.074c-3.495 8.346-2.3 10.311-1.244 10.746s3.271-.1 6.766-8.445l6.318-15.074z" class="cls-4" transform="translate(18.49 23.138)"/>
            <path id="Path_17196" d="M76.509 125.909a1.493 1.493 0 0 0 1.94-.8l.572-1.381a1.483 1.483 0 0 0-2.736-1.144l-.572 1.368a1.493 1.493 0 0 0 .8 1.953z" class="cls-4" transform="translate(18.427 29.659)"/>
            <path id="Path_17197" d="M71.24 126.8c-3.731-3.731-3.731-4.565-3.731-5.311a17.412 17.412 0 0 1 1.667-5.56c-3.445.56-7.786.56-10.4-2.052a5.174 5.174 0 0 1-1.654-5.149c1.082-3.955 7.351-6.119 10.995-7.064.473-.124 1.119-.261 1.891-.435 8.806-1.928 10.982-3.47 11.268-4.428.075-.236-.224-.871-1.057-1.7-1.791-1.791-14.042 3.582-18.072 5.348a43.729 43.729 0 0 1-7.6 2.935A9.3 9.3 0 0 1 44.275 98.5a8.594 8.594 0 0 1 2.351-10.4c4.851-4.254 9.3-2.811 10.982-1.455a2.488 2.488 0 0 1 .361 3.4 2.413 2.413 0 0 1-3.346.4c-.224-.162-1.978-1.244-4.826 1.244a3.818 3.818 0 0 0-1.244 4.664 4.465 4.465 0 0 0 5.087 2.264A52.234 52.234 0 0 0 60.17 96c10.93-4.786 19.59-8.156 23.408-4.338 2.662 2.662 2.711 4.975 2.276 6.467-1.306 4.465-8.059 6.293-14.925 7.786l-1.716.386c-4.515 1.169-7.326 2.91-7.55 3.731a4.466 4.466 0 0 0 .41.473c1.716 1.716 7.251.8 10.447-.373a2.351 2.351 0 0 1 2.612.647 2.413 2.413 0 0 1 .386 2.662A63.157 63.157 0 0 0 72.384 121a85.928 85.928 0 0 0 6.219 6.132 2.313 2.313 0 0 1 .149 3.4 2.4 2.4 0 0 1-3.408.162c-1.753-1.594-3.072-2.864-4.104-3.894z" class="cls-4" transform="translate(10.586 20.785)"/>
        </g>
    </g>
</svg>`,
};

export const download = {
  name: 'download',
  data: `<svg xmlns="http://www.w3.org/2000/svg" id="data-download" width="12.163" height="12.163" viewBox="0 0 12.163 12.163">
    <path id="Path_16375" d="M6.465 9.122A.69.69 0 0 0 7 8.894l4.33-4.333L10.266 3.5 7.225 6.537V0H5.7v6.537L2.664 3.5 1.6 4.561l4.333 4.333a.69.69 0 0 0 .532.228z" class="cls-1" transform="translate(-.384)"/>
    <path id="Path_16376" d="M10.642 13.28H1.52V11H0v3.041a.718.718 0 0 0 .76.76H11.4a.718.718 0 0 0 .76-.76V11h-1.52z" class="cls-1" transform="translate(0 -2.638)"/>
</svg>`,
};

export const smallPin = {
  name: 'small-pin',
  data: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <defs>
        <style>
            .cls-2{fill:#fff}
        </style>
    </defs>
    <g id="Group_20968" opacity="0.3">
        <path id="Rectangle_2345" d="M0 0H16V16H0z" class="cls-2"/>
        <g id="drop" transform="rotate(180 6.912 7.497)">
            <path id="Path_16280" fill="#444" d="M6.223.158a.583.583 0 0 0-.8 0C5.205.364 0 5.287 0 8.515a5.608 5.608 0 0 0 5.825 5.464 5.608 5.608 0 0 0 5.825-5.464c0-3.228-5.205-8.151-5.427-8.357z"/>
            <circle id="Ellipse_319" cx="2" cy="2" r="2" class="cls-2" transform="translate(3.825 5.993)"/>
        </g>
    </g>
</svg>`,
};

export const magnifier = {
  name: 'magnifier',
  data: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.75745 11.1716C8.967 11.6951 8.01911 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7C12 8.01906 11.6951 8.96691 11.1716 9.75733L14.0009 12.5865L12.5866 14.0008L9.75745 11.1716ZM10 7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7C4 5.34315 5.34315 4 7 4C8.65685 4 10 5.34315 10 7Z" fill="#4D7094"/>
</svg>`,
};
